<template>
  <v-row >
    <v-col >
        <p style="color: #1C1E4D !important;"  class="font-weight-bold" :class="[titleDisplay,  textCenter]">{{ title }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: "AppTitle",
    props: {
        title: {
            type: String,
            required: true
        },
        titleDisplay: {
            type: String,
           default: "display-1",
        },
        textCenter: {
          type: String,
          default: 'text-center'
        }
    }
}
</script>

<style>

</style>