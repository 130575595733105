import reporteriaRouter from "@/views/planilla/reporteria/router/reporteriaRouter";
export default [
  {
    path: "gestion-planillas",
    name: "gestion-planilla",
    component: () =>
      import("@/views/acciones-movimientos/planilla/indexPlanilla.vue"),
  },
  {
    path: "menu-planillas/:id",
    name: "menu-planilla",
    component: () =>
      import("@/views/acciones-movimientos/planilla/views/MenuPlanilla.vue"),
  },
  {
    path: "menu-planillas/:id/historico-reapertura-planilla",
    name: "historico-reapertura-planilla",
    component: () =>
      import(
        "@/views/acciones-movimientos/planilla/views/HistoricoReaperturaPlanilla.vue"
      ),
  },
  {
    path: "menu-planillas/:id/boleta-de-pago",
    name: "boleta-pago",
    component: () =>
      import("@/views/acciones-movimientos/planilla/views/BoletaPago.vue"),
  },
  ...reporteriaRouter,
  {
    path: "planilla-config-descuentos/:id_planilla",
    name: "config-descuentos",
    component: () =>
      import("@/views/acciones-movimientos/planilla/views/confDescuentos.vue"),
  },
  {
    path: "planilla/configuracion-ingresos/:id_planilla",
    name: "planilla-configuracion-ingresos",
    component: () =>
      import(
        "@/views/acciones-movimientos/planilla/views/ConfiguracionIngresos.vue"
      ),
  },
  {
    path: "planilla/nomina/:id_planilla/:id_tipo_planilla",
    name: "planilla-nomina",
    component: () =>
      import(
        /* webpackChunkName: "planilla-nomina" */ "@/views/acciones-movimientos/planilla/views/Nomina.vue"
      ),
  },
  {
    path: "planilla/nomina/:id_planilla/:id_tipo_planilla/:id_nomina_planilla",
    name: "planilla-nomina-empleado",
    component: () =>
      import(
        /* webpackChunkName: "planilla-nomina" */ "@/views/acciones-movimientos/planilla/views/VisualizacionBoletaPago.vue"
      ),
  },
  {
    path: "/planilla/detalle-nomina/:id_planilla/:id_nomina",
    name: "planilla-nomina-configuracion",
    component: () =>
      import(
        /* webpackChunkName: "planilla-nomina" */ "@/views/acciones-movimientos/planilla/views/PlanillaNominaConfiguracion.vue"
      ),
  },
  {
    path: "/planilla/detalle/:id_planilla/resumen",
    name: "resumen-planilla",
    component: () =>
      import(
        "@/views/acciones-movimientos/planilla/views/ResumenPlanilla.vue"
      ),
  },
  {
    path: "/planilla/:id_planilla/safi",
    name: "archivos-safi",
    component: () =>
      import(
        "@/views/acciones-movimientos/planilla/views/ArchivosSafiView.vue"
      ),
  },
];
