const refrendaRoutes = [
	{
		path: "/refrenda",
		name: "refrenda",
		component: () => import(/* webpackChunkName: "refrenda" */ "./index.vue"),
	},
	{
		path: "/refrenda/iniciar-proceso",
		name: "refrenda-iniciar-proceso",
		component: () => import(/* webpackChunkName: "refrenda" */ "./views/InitialProcess.vue"),
	},
	{
		path: "/refrenda/steps/:idRefrenda",
		name: "steps",
		component: () => import(/* webpackChunkName: "refrenda" */ "./steps/index.vue"),
	},
	{
		path: "/aprobacion-refrenda",
		name: "aprobacion-refrenda",
		component: () => import (/*webpackChunkName: "refrenda" */ "./views/AprobacionRefrenda.vue"),
	},
	{
		path: "/refrenda/datos/:idRefrenda",
		name: "datos",
		component: () => import(/* webpackChunkName: "datos" */ "./views/DetalleRefrenda.vue"),
	}
]

export default refrendaRoutes