export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
  },

};
