import { http_client } from "@/plugins/http_client";

const organigrama = {
  estructura: () => http_client(`/api/v1/organigrama`, {}, "get"),
  registrar: (body) => http_client("api/v1/organigrama/registrar-padre", body, "post"),
  registrarDependencia: (body) =>
    http_client("/api/v1/organigrama/registrar-hijo", body, "post"),
  actualizarUnidad: (body, id) =>
    http_client(
      `/api/v1/organigrama/editar-unidad/${id}`,
      body,
      "put"
    ),
  registrarJefatura: (body) =>
    http_client("/api/v1/organigrama/registrar-jefatura", body, "post"),
  eliminarJefatura: (id) =>
    http_client(`/api/v1/organigrama/eliminar-jefatura/${id}`, {}, "delete"),
  obtenerJefaturas: (params) =>
    http_client(`/api/v1/organigrama/obtener-jefatura`, params, "get"),
  eliminarUnidad: (id) =>
    http_client(
      `/api/v1/organigrama/eliminar-unidad/${id}`,
      {},
      "delete"
    ),
};

export default {
  organigrama,
};
