import { http_client } from "@/plugins/http_client";
const url = 'api/v1/planilla'
const get = async ( params = {} ) => await http_client(`${url}/acreedor`, params);
const getTipo = async ( params = {} ) => await http_client(`${url}/acreedor/tipo-acreedor`, params);
const getDesc = async ( params = {} ) => await http_client(`${url}/categoria-descuento`, params);
const post = async (body) => await http_client(`${url}/acreedor`, body, "post");
const postFile = async (body) => await http_client(`${url}/acreedor/cargar-datos`, body, "post");
const put = async (id, body) => await http_client(`${url}/acreedor/${id}`, body, "put");
const download = async () => {
    return await http_client(
      `${url}/acreedor/obtener-documento`, // URL
      {}, // Data (params)
      'get', // Method
      {}, // Headers
      true, // Send token
      'blob' // Response type
    );
  };


export default {
	get,
    getDesc,
    getTipo,
    post,
    postFile,
	put,
    download,
}