<template>
  <v-footer absolute inset app class="footer white" id="footer">
    <v-row
      class="py-5 d-flex flex-column flex-md-row justify-space-between align-center "
      no-gutters
    >
    <v-col cols="12" md="4" class="d-flex justify-space-around">
      <p
        v-if="this.$vuetify.breakpoint.xs"
        :class="this.$vuetify.breakpoint.xl ? 'text-subtitle-1 font-weight-light d-flex justify-center mt-5 mr-16' : 'text-subtitle-1 font-weight-light d-flex justify-center mt-5'"
      >
        Secretaría de Innovación de la Presidencia
      </p>
      <div
        v-if="this.$vuetify.breakpoint.xs"
        class="d-flex flex-column align-center justify-center"
      >
        <p class="text-subtitle-1 font-weight-light" style="line-height: 0">
          Secretaría de Innovación
        </p>
        <p class="text-subtitle-1 font-weight-light" style="line-height: 0">
          de la Presidencia
        </p>
      </div>
    </v-col>
    <v-col cols="12" md="4" class="d-flex justify-center">
      <img
        :width="widthLogo + '%'"
        src="../assets/img/logo-goes-footer.png"
        :class="this.$vuetify.breakpoint.xl ? 'mr-3' : ''"
        alt=""
      />
    </v-col>
    <v-col cols="12" md="4" class="d-flex justify-center">
      <p :class="this.$vuetify.breakpoint.xl ? 'mt-5 text-subtitle-1 font-weight-light mr-16' : 'mt-5 text-subtitle-1 font-weight-light'">
        Políticas de privacidad
      </p>
    </v-col>  
      
    </v-row>
  </v-footer>
</template>

<script>
export default {
  computed: {
    widthLogo() {
      let value = 80;
      if (this.$vuetify.breakpoint.sm) return (value = 75);
      if (this.$vuetify.breakpoint.xs) return (value = 75);
      return value;
    },
  },
};
</script>

<style scoped>

.margen{
  margin-right: 7.5%;
}

@media (max-width:900px){
  .footer{
    margin-right:0% !important;
    position: inherit !important;
    
  }
}

.footer_padding{
    padding: 70px 0px 0px 0px !important;
  }
</style>
