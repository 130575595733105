
export default [
  {
    path: 'acciones-movimientos/ascensos',
    name: 'ascenso_personal',
    component: () => import(/* webpackChunkName: "ascenso" */ './indexNuevo.vue'),
  },
  {
    path: 'acciones-movimientos/ascensos/solicitud-ver/:id',
    name: 'ascenso_personal_solicitud-ver',
    component: () => import(/* webpackChunkName: "ascenso_crear" */ './views/AscensoVer.vue'),
  },
  {
    path: 'acciones-movimientos/ascensos/solicitud-aprobacion-jefatura/:id',
    name: 'ascenso_personal_solicitud-aprobacion-jefatura',
    component: () => import(/* webpackChunkName: "ascenso_crear" */ './views/AscensoJefatura.vue'),
    meta: {
      rol:'ROL_JEFATURA',
      
    }
  },
  {
    path: 'acciones-movimientos/ascensos/solicitud-aprobacion-RRHH/:id',
    name: 'ascenso_personal_solicitud-aprobacion-RRHH',
    component: () => import(/* webpackChunkName: "ascenso_crear" */ './views/AscensoRRHH.vue'),
    meta: {
      rol:'ROL_RRHH'
    }
  },
  {
    path: 'acciones-movimientos/ascensos/solicitud-aprobacion-ufi/:id',
    name: 'ascenso_personal_solicitud-aprobacion-ufi',
    component: () => import(/* webpackChunkName: "ascenso_crear" */ './views/AscensoUfi.vue'),
    meta: {
      rol:'ROL_UFI'
    }
  },
  {
    path: 'acciones-movimientos/ascensos/solicitud-aprobacion-titular/:id',
    name: 'ascenso_personal_solicitud-aprobacion-titular',
    component: () => import(/* webpackChunkName: "ascenso_crear" */ './views/AscensoTitular.vue'),
    meta: {
      rol:'ROL_TITULAR'
    }
  },
  {
    path: 'acciones-movimientos/ascensos/solicitud/:id?',
    name: 'ascenso_personal_solicitud',
    component: () => import(/* webpackChunkName: "ascenso_crear" */ './views/AscensoUnidad.vue'),
    meta: {
      rol:{
        ROL_EDITAR: 'ROLE_SOLICITUD_ASCENSO_AGREGAR_PERSONA',
        ROL_ELIMINAR: 'ROLE_SOLICITUD_ASCENSO_ELIMINAR_PERSONA',
      },
      solventar: true
    }
  }
]