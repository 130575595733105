import { http_client } from "@/plugins/http_client";

function getParams(params) {
  return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}

const tipoDocumento = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_tipo_documento/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_tipo_documento/${body.id}`, body, "put"),
  desactivar: (body) =>
    http_client(`/api/ctl_tipo_documento/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_tipo_documento`, body, "post"),
};

const paises = {
  listar: ({ params, data }) =>
    http_client(`/api/ctl_pais/search?` + getParams(params), data, "post"),
  actualizar: (body) => http_client(`/api/ctl_pais/${body.id}`, body, "put"),
  desactivar: (body) => http_client(`/api/ctl_pais/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_pais`, body, "post"),
};

const departamentos = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_departamento/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_departamento/${body.id}`, body, "put"),
  desactivar: (body) =>
    http_client(`/api/ctl_departamento/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_departamento`, body, "post"),
};

const municipios = {
  listar: ({ params, data }) =>
    http_client(`/api/ctl_municipio/search?` + getParams(params), data, "post"),
  listarTodos: ({ params, data }) =>
    http_client(`/api/ctl_municipios/all` + getParams(params), data, "get"),
  listaOrdenada: (params) =>
    http_client(`/api/ctl_municipio/search`, params, "post"),
  actualizar: (body) =>
    http_client(`/api/ctl_municipio/${body.id}`, body, "patch"),
  desactivar: (body) =>
    http_client(`/api/ctl_municipio/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_municipio`, body, "post"),
};

const estadoCivil = {
  listar: ({ params, data }) =>
    http_client(`/api/ctl_estado_civil/?` + getParams(params), "get"),
  actualizar: (body) =>
    http_client(`/api/ctl_estado_civil/${body.id}`, body, "patch"),
  desactivar: (body) =>
    http_client(`/api/ctl_estado_civil/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_estado_civil`, body, "post"),
};

const tipoSangre = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_tipo_sangre/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_tipo_sangre/${body.id}`, body, "patch"),
  desactivar: (body) =>
    http_client(`/api/ctl_tipo_sangre/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_tipo_sangre`, body, "post"),
};

const tipoSexo = {
  listar: ({ params, data }) =>
    http_client(`/api/ctl_sexo/search?` + getParams(params), data, "post"),
  actualizar: (body) => http_client(`/api/ctl_sexo/${body.id}`, body, "patch"),
  desactivar: (body) => http_client(`/api/ctl_sexo/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_sexo`, body, "post"),
};

const parentesco = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_parentesco/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_parentesco/${body.id}`, body, "patch"),
  desactivar: (body) =>
    http_client(`/api/ctl_parentesco/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_parentesco`, body, "post"),
};

const formacionAcademica = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_formacion_educativa/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_formacion_educativa/${body.id}`, body, "put"),
  desactivar: (body) =>
    http_client(`/api/ctl_formacion_educativa/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_formacion_educativa`, body, "post"),
};

const nivelEducativo = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_nivel_educativo/search?` + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_nivel_educativo/${body.id}`, body, "put"),
  desactivar: (body) =>
    http_client(`/api/ctl_nivel_educativo/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_nivel_educativo`, body, "post"),
};

const Institucion = {
  listar: ({ params, data }) =>
    http_client(
      "/api/ctl_institucion/search?" + getParams(params),
      data,
      "post"
    ),
  listaOrdenada: (params) =>
    http_client("/api/ctl_institucion/search", params, "post"),
  actualizar: (body, id) =>
    http_client(`/api/institucion/${id}/actualizar`, body, "post", {
      headers: { "content-type": "multipart/form-data" },
    }),
  desactivar: (body) =>
    http_client("/api/ctl_institucion/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_institucion", body, "post", {
    headers: { "content-type": "multipart/form-data" },
  }),
};

const tipoInstitucion = {
  listar: ({ params, data }) =>
    http_client(
      `/api/ctl_tipo_institucion/search?` + getParams(params),
      data,
      "post"
    ),
  listaOrdenada: (params) =>
    http_client(`/api/ctl_tipo_institucion/search`, params, "post"),
  actualizar: (body) =>
    http_client(`/api/ctl_tipo_institucion/${body.id}`, body, "put"),
  desactivar: (body) =>
    http_client(`/api/ctl_tipo_institucion/${body.id}`, {}, "delete"),
  crear: (body) => http_client(`/api/ctl_tipo_institucion`, body, "post"),
};

const EstadoEmpleado = {
  listar: ({ params, data }) =>
    http_client(
      "/api/ctl_estado_empleado/search?" + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client(`/api/ctl_estado_empleado/${body.id}`, body, "put"),
  desactivar: (body) =>
    http_client("/api/ctl_estado_empleado/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_estado_empleado", body, "post"),
};

const Discapacidad = {
  listar: ({ params }) =>
    http_client("/api/ctl_discapacidad/?" + getParams(params), "get"),
  actualizar: (body) =>
    http_client("/api/ctl_discapacidad/" + body.id, body, "put"),
  desactivar: (body) =>
    http_client("/api/ctl_discapacidad/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_discapacidad", body, "post"),
};

const TipoEstablecimiento = {
  listar: ({ params, data }) =>
    http_client(
      "/api/mnt_tipo_establecimiento/search?" + getParams(params),
      data,
      "post"
    ),
  listaOrdenada: (params) =>
    http_client(`/api/mnt_tipo_establecimiento/search`, params, "post"),
  actualizar: (body) =>
    http_client("/api/mnt_tipo_establecimiento/" + body.id, body, "patch"),
  desactivar: (body) =>
    http_client("/api/mnt_tipo_establecimiento/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/mnt_tipo_establecimiento", body, "post"),
};

const Establecimiento = {
  listar: ({ params, data }) =>
    http_client(
      "/api/mnt_establecimiento/search?" + getParams(params),
      data,
      "post"
    ),
  listaOrdenada: (params) =>
    http_client(`/api/mnt_establecimiento/search`, params, "post"),
  actualizar: (body) =>
    http_client("/api/mnt_establecimiento/" + body.id, body, "put"),
  desactivar: (body) =>
    http_client("/api/mnt_establecimiento/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/mnt_establecimiento", body, "post"),
};

const EstadoPuestoFuncional = {
  listar: ({ params, data }) =>
    http_client(
      "/api/mnt_estado_cargo_funcional/search?" + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client("/api/mnt_estado_cargo_funcional/" + body.id, body, "patch"),
  desactivar: (body) =>
    http_client("/api/mnt_estado_cargo_funcional/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/mnt_estado_cargo_funcional", body, "post"),
};

const PuestoFuncional = {
  listar: ({ params, data }) =>
    http_client(
      "/api/mnt_puesto_funcional/search?" + getParams(params),
      data,
      "post"
    ),
  actualizar: (body) =>
    http_client("/api/mnt_puesto_funcional/" + body.id, body, "patch"),
  desactivar: (body) =>
    http_client("/api/mnt_puesto_funcional/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/mnt_puesto_funcional", body, "post"),
};

const InstitucionesFinancieras = {
  listar: ({ params, data }) =>
    http_client("/api/institucion-financiera/search?" + getParams(params), data, "post"),
  actualizar: (body) => http_client("/api/institucion-financiera/" + body.id, body, "patch"),
  desactivar: (body) => http_client("/api/institucion-financiera/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/institucion-financiera", body, "post"),
};

const Pagaduria = {
  listar: ({ params, data }) =>
    http_client("/api/mnt_pagaduria/search?" + getParams(params), data, "post"),
  actualizar: (body) =>
    http_client("/api/mnt_pagaduria/" + body.id, body, "patch"),
  desactivar: (body) =>
    http_client("/api/mnt_pagaduria/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/mnt_pagaduria", body, "post"),
};

//ctl_tipo_acreedor
const TipoAcreedor = {
  listar: ({ params, data }) =>
    http_client(
      "/api/ctl_tipo_acreedor/search?" + getParams(params),
      data,
      "post"
    ),
  listaOrdenada: (body) =>
    http_client("/api/ctl_tipo_acreedor/search?", body, "post"),
  actualizar: (body) =>
    http_client("/api/ctl_tipo_acreedor/" + body.id, body, "patch"),
  desactivar: (body) =>
    http_client("/api/ctl_tipo_acreedor/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_tipo_acreedor", body, "post"),
};

//ctl_acreedor
const Acreedor = {
  listar: ({ params, data }) =>
    http_client("/api/ctl_acreedor/search?" + getParams(params), data, "post"),
  actualizar: (body) =>
    http_client("/api/ctl_acreedor/" + body.id, body, "patch"),
  desactivar: (body) =>
    http_client("/api/ctl_acreedor/" + body.id, {}, "delete"),
  crear: (body) => http_client("/api/ctl_acreedor", body, "post"),
  cargarArchivo: (archivo) =>
    http_client("/api/importar/acreedor", archivo, "post", {
      headers: { "content-type": "multipart/form-data" },
    }),
  plantilla: (body) =>
    http_client(
      "/api/importar/acreedor/descargar",
      body,
      "post",
      {
        "content-type": "blob",
      },
      true,
      "arraybuffer"
    ),
};

const tipoUnidades = {
  listar: () => http_client("/api/tipo-unidades/listado", "get"),
};

const estructuras = {
  listar: () => http_client("/api/primer-paso/obtener-estructura", "get"),
}

export default {
  tipoDocumento,
  paises,
  departamentos,
  municipios,
  Institucion,
  EstadoEmpleado,
  Discapacidad,
  TipoEstablecimiento,
  Establecimiento,
  EstadoPuestoFuncional,
  PuestoFuncional,
  InstitucionesFinancieras,
  Pagaduria,
  TipoAcreedor,
  Acreedor,
  estadoCivil,
  tipoSangre,
  tipoSexo,
  parentesco,
  formacionAcademica,
  nivelEducativo,
  tipoInstitucion,
  tipoUnidades,
  estructuras,
};
