<template>
    <div>
        <v-data-table v-bind="dataConfig" :item-class="itemRowBackground" :show-rows-border="false"
            no-data-text="Sin resultados" :items-per-page="itemsPerPage" :class="tableClass" hide-default-footer
            @page-count="pageCount = $event" :items="itemsTest" :headers="headersFormat"
            :disable-pagination="pagination" >
            <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
                <slot :name="slot" v-bind="props"> </slot>
            </template>
            <template v-if="pagination" v-slot:footer>
                <div class="text-center my-10" v-if="itemsFormated.length !== 0">
                    <app-custom-pagination-component v-model="page" :total-pages="totalPages" @change="changePagina"
                        class="principalPagination"></app-custom-pagination-component>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import AppCustomPaginationComponent from "./AppCustomPaginationComponent.vue";
export default {
    name: "AppDataTable",
    components: {
        AppCustomPaginationComponent
    },
    props: {
        dataConfig: {
            type: Object,
            default: () => { },
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        totalPages: {
            type: Number,
            default: 10,
        },
        pagination: {
            type: Boolean,
            default: true,
        },
        tableClass:{
            type:String,
            default:'elevation-3 rounded-xl striped-rows no-hover'
        },
        correlativo: {
            type: Boolean,
            default: true,
        },
        sortDescDefault: {
            type: Boolean,
            default: false,
        },
        currentPage:{
            type: Number,
            default: 1,
        },
        correlativeText:{
            type: String,
            default: 'N°'
        }
    },
    data() {
        return {
            page: 1,
            sortBy: "id",
            sortDesc: this.sortDescDefault,
        };
    },
    computed: {
        itemsFormated() {
         if (!Array.isArray(this.dataConfig.items)) return []
            return this.dataConfig.items.map((value, index) => {
                if (this.correlativo) {
                    value.correlativo = this.dataConfig.items.length < 999
                        ? ("000" + (this.page * this.itemsPerPage - this.itemsPerPage + (index + 1))).slice(-3)
                        : this.page * this.itemsPerPage - this.itemsPerPage + (index + 1);
                }
                return value;
            });
        
        },
        itemsTest() {
            return this.itemsFormated.sort((a, b) => b.id - a.id);
        },
        headersFormat() {
            const headers = []
            if (this.correlativo) {
                headers.push({
                    text: this.correlativeText,
                    value: "correlativo",
                    sortable: false,
                });
            }
            headers.push(...this.dataConfig.headers);
            return headers.map((value, index) => {
                value.class =
                    "no-border font-weight-black text-subtitle-1 black--text py-5";
                return value;
            });
        },
    },
    watch:{
        currentPage(){
            this.page = this.currentPage
        }
    },
    methods: {
        changePagina(nuevo, old) {
            this.$emit("paginationChange", nuevo);
        },
        itemRowBackground(item) {
            //return item.correlativo % 2 === 1 ? "bgTableFila " : "bgWhite";
        },
    },
    created() {
        this.page = this.currentPage
    } 
};
</script>
<style scoped>
/* Alternar colores en las row */
.principalPagination >>> .pagination {
  display: block !important;
}
::v-deep .striped-rows tbody tr:nth-child(odd) {
  background-color: #ededf1; 
}

::v-deep .striped-rows tbody tr:nth-child(even) {
  background-color: white;
}
/* Quitar efecto hover */
::v-deep .no-hover tbody tr:hover {
  background-color: #14153732 !important;
}
</style>