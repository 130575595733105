<template>
  <v-chip
    v-if="isBoolean"
    class="text-center d-block"
    :color="selectedColor"
    text-color="white"
  >
    {{ state ? textActive : textInactive }}
  </v-chip>

  <v-chip
    v-else
    class="text-center d-block"
    :color="selectedColor.color"
    text-color="white"
  >
    {{ state[selectedColor.value] }}
  </v-chip>
</template>

<script>
export default {
  name: "AppEstadoTableComponent",
  props: {
    state: {
      type: [Object, Boolean],
    },
    textActive: {
      type: String,
      default: "Activo",
    },
    textInactive: {
      type: String,
      default: "Inactivo",
    },

    colors: {
      type: Object,
      default: {
        active: "rgb(68,197,176)",
        inactive: "default",
        estados: {
          1: {
            color: "red",
            value: "nombre",
          },
        },
      },
    },
  },

  data() {
    return {
    
    };
  },

  computed: {
    isBoolean() {
      return typeof this.state === "boolean";
    },

    selectedColor() {
      if (this.isBoolean)
        return this.state ? this.colors.active : this.colors.inactive;

      return this.colors.estados[this.state?.id];
    },
  },
};
</script>

<style></style>
