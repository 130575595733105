<template>
  <v-dialog v-model="show" max-width="900" persistent>
    <v-card class="pa-4">
      <v-row>
        <v-col class="d-flex justify-end" cols="12">
          <v-btn small icon text @click="close" class="mr-5 mt-5">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title class="d-flex justify-center">
        <p :class="$vuetify.breakpoint.smAndUp ? 'text-h3' : 'text-h4'" style="word-break: keep-all; text-align: center; color: #1C1E4D; font-weight: bold">
          {{ title }}
        </p>
      </v-card-title>
      <v-card-text class="mt-5">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          class="text-none"
          color="bluePrimary"
          outlined
          rounded
          @click="close"
          >Cancelar</v-btn
        >
        <v-btn
          color="bluePrimary white--text text-none"
          rounded
          @click="confirm"
          >{{ textBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogComponent",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    textBtn: {
      type: String,
      default: "Confirmar",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped>
:deep(.v-dialog) {
  box-shadow: none;
}

.v-card {
  border-radius: 50px;
}

@media screen and (min-width: 280px) {
  .custom_title {
    font-size: 16px;
  }
}
</style>
