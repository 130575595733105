import Vue from 'vue'
import Vuex from 'vuex'
import utils from '../modules/utils'
import jwtDecode from "jwt-decode";
import estructura from '../views/estructura/store/index'
import destacados from '../views/acciones-movimientos/store/index'
import reporteria from '../views/planilla/reporteria/store';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        userInfo: {},
        expiracionToken: false,
    },
    mutations: {
        setToken(state, payload) {
            state.token = payload
        },
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setExpiracionToken(state) {
            let fechaExpiracion = new Date(state.userInfo.exp * 1000);
            let fechaActual = new Date();
            state.expiracionToken = fechaActual > fechaExpiracion;
        }
    },
    actions: {
        setAuth({ commit }, payload) {
            const { token, refreshToken } = payload

            commit('setUserInfo', jwtDecode(token))
            commit('setToken', token)
            localStorage.setItem('token', token)
            localStorage.setItem('refresh_token', refreshToken)
        },
    },
    modules: {
        utils,
        estructura,
        destacados,
        reporteria
    }
})
