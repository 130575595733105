import {http_client} from '@/plugins/http_client';

const getEstablecimientosInstitucion = async (idInstitucion, params) => await http_client(`api/establecimientos-institucion/${idInstitucion}`, params, 'get');
const getPlazasEstablecimiento = async (idEstablecimiento, params) => await http_client(`api/plazas-establecimiento/${idEstablecimiento}`, params, 'get');

const getReportePlaza = async (params) => await http_client(`api/reportes/plazas`, params, 'get');

export default {
	getEstablecimientosInstitucion,
  getPlazasEstablecimiento,
  getReportePlaza
};
