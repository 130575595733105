<template>
    <div>
        <div style="height: 50vh;" class="d-flex justify-center align-center">
            <v-card
            class="pa-5"
            elevation="15"
            :width="this.$vuetify.breakpoint.mdAndDown ? '70vw':'40vw'"
            >
                <v-row v-if="flag_cargando" >
                    <v-col class="text-center" cols="12">
                        <h2>Cargando datos</h2>
                    </v-col>
                    <v-col class="text-center" cols="12">
                        <!-- <v-progress-linear
                            height="10px"
                            buffer-value="0"
                            stream
                            :value="AvanceCarga"
                        >
                        </v-progress-linear> -->
                        <v-progress-circular
                            :rotate="360"
                            :size="100"
                            :width="15"
                            :value="AvanceCarga"
                            color="primary"
                            indeterminate
                        >
                        </v-progress-circular>
                        <!-- <v-progress-circular 
                            width="20"
                            stream
                            rotate="360"
                            size="120"
                            :value="AvanceCarga">
                        </v-progress-circular> -->
                    </v-col>
                    <v-col class="text-center" cols="12">
                        <h4>Por favor espere.</h4>
                    </v-col>
                </v-row>
                <v-row v-if="!flag_cargando" >
                    <v-col class="text-center" cols="12">
                        <h2>Error al cargar datos</h2>
                    </v-col>
                    <v-col class="text-center" cols="12">
                        <!-- <v-progress-circular
                            :rotate="360"
                            :size="100"
                            :width="15"
                            :value="AvanceCarga"
                            color="primary"
                            indeterminate
                        >
                        </v-progress-circular> -->
                        <v-icon
                            size="100"
                            color="primary"
                        >
                            mdi-alert-octagon
                        </v-icon>
                    </v-col>
                    <v-col class="text-center" cols="12">
                        <v-btn
                        text
                        class="text-capitalize"
                        @click="fn_regresar"
                        >
                        <h2>Regresar</h2>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </div>        
    </div>
</template>
<script>
export default {
    props: {
        AvanceCarga: {
            type: Number,
            default: 0,
        },
        flag_cargando:{
            type: Boolean,
            default: true,
        },
        fn_regresar:{
            type: Function,
            default:()=>{}
        }
        // OrdenesItems_ini: {
        //     type: Array,
        //     default: [],
        // },

    },
}
</script>