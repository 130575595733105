import Vue from "vue";

const hasRole = (role) => {
    const roles = JSON.parse(localStorage.getItem("roles"));
    return roles.includes(role);
}

Vue.prototype.hasRole=hasRole;

export {
    hasRole
}