<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    eager
    min-width="auto"
    :disabled="readonly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-role="rol"
        :label="label"
        prepend-inner-icon="mdi-calendar-month"
        @blur="$emit('blur', $event)"
        maxlength="10"
        :rules="rules"
        :dense="dense"
        v-mask="'##-##-####'"
        :filled="filled"
        :outlined="outlined"
        :placeholder="placeholder"
        :rounded="rounded"
        v-bind="attrs"
        v-model="fechaFormatted"
        v-on="on"
        :error-messages="errorMessages"
        color="TCsecondary"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="!readonly"
        :class="`${classes}`"
        ref="input"
      />
    </template>
    <v-date-picker
      class="pt-2"
      :show-current="current"
      :disabled="disabled"
      :readonly="readonly"
      :max="max"
      :min="min"
      locale="es"
      color="primary"
      no-title
      v-model="fecha"
      @input="menu = false"
    />
  </v-menu>
  
</template>

<script>
import moment from "moment";
const today = moment(new Date());

export default {
  name: "Global-Date-Picker",
  props: {
    current: null,
    classes: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    value: {
      type: String,
      default: () => "",
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    hidesdetails: {
      type: Boolean,
      default: () => false,
    },
    filled: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: Boolean,
      default: () => false,
    },
    rounded: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: () => "dd-mm-yyyy",
    },
    reset: {
      type: Number
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    isValidMaxDate: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    label:{
      type: String,
      default: () => "Ascenso vigente a partir de*"
    },
    rol:{
      type: String,
      default: () => ""
    }
  },
  data: () => ({
    menu: false,
    fecha: null,
    activePicker: null,
  }),
  computed: {
    fechaFormatted: {
      get() {
        return this.fecha ? moment(this.fecha).format("DD-MM-YYYY") : '';
      },
      set(value) {
        if(value) {
          if(!value.match(/^\d{2}-\d{2}-\d{4}$/)) {
            return null;
          }
          if(this.isValidMaxDate) {
            const dateToCompare = moment(value, 'DD-MM-YYYY');
            const isValid =  dateToCompare <= today;
            if(isValid) {
              this.menu = false;
              this.fecha = moment(value, "DD-MM-YYYY").format("YYYY-MM-DD");
            } else {              
              this.$refs.input.reset();
              this.menu = false;
              this.fecha = null;
              this.$emit("input-error", 'La fecha no puede ser mayor a la fecha actual');
            }
          } else {
            this.fecha = moment(value, "DD-MM-YYYY").format("YYYY-MM-DD");
          }
        }
      },
    },
  },

  watch: {
    value(newValue) {
      if (this.fecha == "Fecha inválida") {
        this.fecha = '';
      } else {
        this.fecha = newValue;
      }
    },
    fecha() {
      this.$emit("input", this.fecha);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"), 0);
    },
    reset(){
      this.$refs.input.reset();
      this.menu = false;
      this.fecha = null;
    }
  },
  created() {
    this.fecha = this.value;
  },
};
</script>
