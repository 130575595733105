<template>
    <main>
      <v-dialog
        v-model="dialogAlert"
        width="650"
        persistent
      >
        
  
        <v-card>
          <v-card-title >
           <!-- <app-title :title="titulo" /> -->
           <div class="justify-center" style="width: 100%;">
            <p :style="clasesTitulo">{{ titulo }}</p>
           </div>
         
          </v-card-title>
  
          <v-card-text>
           <div class="text-center font-weight-medium" style="color: black;">
            {{ mensaje }}
           </div>
           <div class="text-center font-weight-medium" style="color: black;">
            {{ message2 }}
           </div>
          </v-card-text>
  
          <!-- <v-divider></v-divider> -->
  
          <v-card-actions>
            <v-col class="d-flex justify-center">
              <button-components
                :colors="'1C1E4D'"
                :outlined="true"
                :title="btnAccionesTxtCancelar"
                @btnAccion="dialogCerrar"
              />
  
              <button-components
                :colors="colorBtntAccion"
                :title="btnAccionesTxt"
                :outlined="btnOutlined"
                class="ml-5"
                @btnAccion="dialogAceptar"
              />
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main>
  </template>
  
  <script>
import AppTitleComponent from './AppTitleComponent.vue';
import ButtonComponents from '../views/acciones-movimientos/components/ButtonComponents.vue';
  export default {
    components: { AppTitleComponent, ButtonComponents},
    props:{
      titulo: {
          type: String,
          required: true
      },
      dialogAlert: {
          type: Boolean,
          default: false
      },
      mensaje: {
        type: String,
        required: true
      },
      message2: {
        type: String,
        
      },
      btnAccionesTxt: {
        type: String,
        required: true
      },
      clasesTitulo: {
        type: Object,
        required: true
      },
      btnAccionesTxtCancelar: {
        type: String,
        required: true
      },
      colorBtntAccion:{
        type: String,
        default:'#1C1E4D'
      },
      btnOutlined: {
        type: Boolean,
        default: false
      }
    },
    methods:{
      dialogCerrar(){
        this.$emit('dialogCerrarAlert');
      },
      dialogAceptar(){
        this.$emit('dialogAceptaralert');
      }
    }
  
  }
  </script>
  
  <style>
  
  </style>