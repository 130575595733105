import { http_client } from "@/plugins/http_client";
const url = "api/v1/planilla";
const get = async (params = {}) => await http_client(`${url}/alcaldia`, params);
const getVialidad = async (params = {}) =>
  await http_client(`${url}/vialidad/vigente`, params);
const getAcreedor = async (params = {}) =>
  await http_client(`${url}/acreedor`, params);
const post = async (body) => await http_client(`${url}/alcaldia`, body, "post");
const put = async (id, body) =>
  await http_client(`${url}/alcaldia/${id}`, body, "put");
const putMonto = async (body) =>
  await http_client(`${url}/vialidad/vigente`, body, "put");

export default {
  get,
  getVialidad,
  getAcreedor,
  post,
  put,
  putMonto,
};
