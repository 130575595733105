export default {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload;
        },
        removeItem(state, item) {
            if (item.children?.length) return;
            function deepDelete(array) {
                array.forEach((child, index) => {
                    if (item.codigo === child.codigo)
                        return !!array.splice(index, 1);
                    else if (child.children) deepDelete(child.children);
                });
            }
            return deepDelete(state.items);
        },
    },
};
