import { http_client } from "@/plugins/http_client";
const VUE_APP_PORTAL_EMPLEADO = `${process.env.VUE_APP_PORTAL_EMPLEADO}`;

const url = "/api/renuncia";

const obtenerRenuncias = async (params) =>
  await http_client(`${url}/index`, params, "get");

const listarUnidadesOrganizativas = async () =>
  await http_client(`${url}/unidad-organizativa`, {}, "get");

const infoSolicitante = async (filtros = {}) =>
  await http_client(
    "api/renuncia/informacion/establecimiento-solicitante",
    filtros
  );

const getInfoEmpleado = async (params = {}) =>
  await http_client(`/api/renuncia/informacion-empleado`, params);

const getInfoRenuncia = async (params = {}) =>
  await http_client(`/api/renuncia/informacion-renuncia`, params);

const getTipoRenuncia = async () =>
  await http_client(`/api/renuncia/tipo-renuncia`, {}, "get");

const postDocumentos = async (params) =>
  await http_client(`/api/renuncia/guardar-documento`, params, "post", {
    headers: { "content-type": "multipart/form-data" },
  });

const postDocumentosActa = async (params) =>
  await http_client(`/api/renuncia/subir-acta`, params, "post", {
    headers: { "content-type": "multipart/form-data" },
  });

const getDocumentos = async (id_solicitud) =>
  await http_client(`/api/renuncia/obtener-documento/${id_solicitud}`);

const getActas = async (id_solicitud) =>
  await http_client(`/api/renuncia/obtener-acta/${id_solicitud}`);

const deleteDocumentos = async (id, filtros) =>
  await http_client(
    `/api/renuncia/eliminar-documento/${id}`,
    filtros,
    "delete"
  );

const deleteActas = async (id, filtros) =>
  await http_client(`/api/renuncia/eliminar-acta/${id}`, filtros, "delete");

const verPdfDocumentos = async (id) =>
  await http_client(`/api/renuncia/ver-documento/${id}`);

const verPdfActas = async (id) =>
  await http_client(`/api/renuncia/ver-acta/${id}`);

const accionesFase = async (params) =>
  await http_client(`/api/renuncia/aceptar`, params, "put");

const obtenerFaseRenuncia = async (params) =>
  await http_client(`/api/renuncia/fase`, params, "get");

const descargarActa = async (id) =>
  await http_client(`/api/renuncia/descargar-acta/${id}`);

const montoDeposito = async (params) =>
  await http_client(`/api/renuncia/agregar/monto-renuncia`, params, "put");
const verPdfCartas = async (id) => await http_client(`${VUE_APP_PORTAL_EMPLEADO}/api/v1/portal/obtener-documento-renuncia/${id}`,{}, 'get');

export default {
  obtenerRenuncias,
  listarUnidadesOrganizativas,
  infoSolicitante,
  getInfoEmpleado,
  getInfoRenuncia,
  getTipoRenuncia,
  postDocumentos,
  getDocumentos,
  deleteDocumentos,
  verPdfDocumentos,
  accionesFase,
  obtenerFaseRenuncia,
  postDocumentosActa,
  getActas,
  deleteActas,
  verPdfActas,
  descargarActa,
  montoDeposito,
  verPdfCartas,
};
