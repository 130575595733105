export default [
  {
    path: "/nomina",
    name: "nomina",
    component: () => import(/* webpackChunkName: "nomina" */ "./index.vue"),
  },
  {
    path: "/nomina/expediente-empleado/:idPersona",
    name: "nomina-expediente-empleado",
    component: () =>
      import(
        /* webpackChunkName: "nomina" */ "./components/expedienteEmpleado.vue"
      ),
  },
  {
    path: "/nomina/ordenes-descuento/:idPersona/empleado-puesto/:idEmpleadoPuesto",
    name: "nomina-ordenes-descuento",
    component: () =>
      import(
        /* webpackChunkName: "nomina" */ "./components/IndexOrdenDescuento.vue"
      ),
  },
];
