import { http_client } from "@/plugins/http_client";


///api/unidad-organizativa/puestos
const puestoUnidad = {
    listar: () => http_client('/api/unidad-organizativa/puestos', {}, "get" )
};

export default {
    puestoUnidad
}
