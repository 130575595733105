export default [
    {
      path: "acciones-movimientos/permuta",
      name: "permuta_personal",
      component: () =>
        import( /* webpackChunkName: "permuta_personal" */ "@/views/acciones-movimientos/permutas/index.vue" ),
    },
    {
      path: "acciones-movimientos/permuta/solicitud",
      name: "permuta_personal_solicitud",
      component: () =>
        import( /* webpackChunkName: "permuta_personal_crear" */ "@/views/acciones-movimientos/permutas/views/solicitudPermuta.vue" ),
    },
    {
      path: "acciones-movimientos/permuta/solicitud/ver/:id/:type?/:idSolicitud?/:id_unidad?/:id_est?",
      name: "permuta_personal_solicitud_ver",
      component: () =>
        import( /* webpackChunkName: "permuta_personal_crear" */ "@/views/acciones-movimientos/permutas/views/solicitudPermuta.vue" ),
    },
    {
      path: "acciones-movimientos/permuta/solicitud/:type/:idSolicitud?/:idEmpleado?",
      name: "permuta_personal_solicitud_crear",
      component: () =>
        import( /* webpackChunkName: "permuta_personal_crear" */ "@/views/acciones-movimientos/permutas/views/solicitudPermuta2.vue" ),
    },
]