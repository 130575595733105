import { http_client } from '@/plugins/http_client';

const baseUrl = 'api/v1/gestion-roles/rol'

const getListadoRoles = async (params) => await http_client(`${baseUrl}`, params, 'get');

const getRolById = async (id, params) => await http_client(`${baseUrl}/${id}`, params, 'get');

const postRol = async (data) => await http_client(`${baseUrl}`, data, 'post')

const putRol = async (id, data) => await http_client(`${baseUrl}/actualizar/${id}`, data, 'put');

const putEstadoRol = async (id) => await http_client(`${baseUrl}/${id}/estado`, {}, 'put');

const getHistorialRol = async (id, params) => await http_client(`${baseUrl}/${id}/historial`, params, 'get');

const getListaPermisos = async (params) => await http_client(`${baseUrl}/permisos/lista`, params, 'get');

export default {
    getListadoRoles,
    getRolById,
    postRol,
    putRol,
    putEstadoRol,
    getHistorialRol,
    getListaPermisos
};