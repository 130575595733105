import estructura from "./estructura.js";
import cifrados from "./cifrados.js";
import conceptosPresupuestarios from "./conceptosPresupuestarios.js";
import plazaNominal from "./plazaNominal.js";
import cargosFuncionales from "./cargosFuncionales.js";
import empleados from "./empleados.js";

export default {
    namespaced: true,
    state: {
        step: 1,
        steps: [
            "Estructura",
            "Cifrados",
            "Conceptos presupuestarios",
            "Plaza nominal",
            "Cargos funcionales",
            "Empleados",
            "Resumen",
        ],
        establecimiento: {},
        cargaFinalizada: false,
    },
    mutations: {
        nextStep(state) {
            state.step++;
        },
        prevStep(state) {
            state.step--;
        },
        setStep(state, value) {
            state.step = value;
        },
        setEstablecimiento(state, value) {
            state.establecimiento = value;
        },
        setCargaFinalizada(state, value) {
            state.cargaFinalizada = value;
        },
    },
    actions: {
        async pasoActual({ commit }) {
            const {
                data: { data },
            } = await this._vm.services.estructura.pasoActual();
            commit("setStep", data?.paso || 1);
            commit("setEstablecimiento", data?.establecimiento || {});
            commit("setCargaFinalizada", data?.carga_inicial_finalizada || false);
        },
       
    },
    modules: {
        estructura,
        cifrados,
        conceptosPresupuestarios,
        plazaNominal,
        cargosFuncionales,
        empleados,
    },
};
