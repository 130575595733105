// const rolesPermissions = ['ROL_RRHH','ROL_TITULAR', 'ROL_UNIDAD']
const rolesPermissions = JSON.parse(localStorage.getItem('roles')) 
const roleDirective = {
  inserted(el, binding, vnode) {
    const { permission, action, ...rest } = binding.value;
    const role = Object.values(rest).join('');
    const hasPermission = rolesPermissions?.includes(permission||role);
    if (!hasPermission) {
      const isVueComponent = vnode.componentInstance;
      const defaultAction = isVueComponent ? 'disable' : 'hide';
      
      switch (action || defaultAction) {
        case 'hide':
          el.style.display = 'none';
          break;
        case 'disable':
          if (isVueComponent) {
            vnode.componentInstance.$data.disabled = true;
            el.style.pointerEvents = 'none';
            el.firstElementChild.firstElementChild.firstElementChild.style.backgroundColor= '#0000001f'
            el.style.opacity = '0.4';
          } else {
            el.disabled = true;
            el.style.pointerEvents = 'none';
            el.style.opacity = '0.4';
          }
          break;
        default:
          console.warn(`Acción desconocida: ${action}`);
      }
    }
  }
};

export default roleDirective;
