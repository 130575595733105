import { http_client } from "@/plugins/http_client";

function getParams(params) {
  return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}

const categoriaPlaza = {
  listadoPlazas: (body) =>
    http_client(`/api/v2/catalogo_categoria_puesto/plazas` ,body, "get"),
  listar: (params) =>
    http_client("/api/v1/catalogo_categoria_puesto?" + getParams(params.data)),
  crear: (body) =>
    http_client("/api/mnt_categoria_plaza_establecimiento", body, "post"),
  actualizar: (id, body) =>
    http_client(`/api/v1/mnt_categoria_plaza_establecimiento/${id}`, body, "put"),
  desactivar: (body) =>
    http_client(
      "/api/v1/mnt_categoria_plaza_establecimiento/" + body,
      {},
      "delete"
    ),
  listarPlazas: ({ params, data }) =>
    http_client(
      "/api/mnt_detalle_plaza/search?" + getParams(params),
      data,
      "post"
    ),
};

export default {
  categoriaPlaza,
};
