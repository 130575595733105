import { http_client } from "@/plugins/http_client";

const obtenerEstablecimientos = async (params = {}) => 
  await http_client("/api/listar-establecimientos", params, "get");

const obtenerIntituciones = async (params = {}) =>
    await http_client("/api/instituciones", params, "get");

const obtenerTiposDocumentos = async (params = {}) =>
    await http_client("/api/tipo-documento", params, "get");

const obtenerTipoMovimientoSolicitud = async (params = {}) => await http_client("/api/destacamiento/tipo-solicitud", params, "get");

const obtenerEstablecimientosPermuta = async (params = {}) => await http_client("/api/permuta/listar-establecimiento", params, "get");
const obtenerUnidades = async (params = {}) => await http_client("/api/unidad", params, "get");

export default {
  obtenerEstablecimientos,
  obtenerIntituciones,
  obtenerTiposDocumentos,
  obtenerTipoMovimientoSolicitud,
  obtenerEstablecimientosPermuta,
  obtenerUnidades
};
