export default [
    {
      path: "acciones-movimientos/despidos",
      name: "despido_personal",
      component: () =>
        import( /* webpackChunkName: "despido_personal" */ "@/views/acciones-movimientos/despidos/index.vue" ),
    },
    {
      path: "acciones-movimientos/despidos/solicitud",
      name: "despido_personal_solicitud",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud" */ "@/views/acciones-movimientos/despidos/views/solicitudDespido.vue" ),
    },
    {
      path: "acciones-movimientos/despidos/solicitud/detalle/:idSolicitud/:isTribunal?",
      name: "despido_personal_solicitud_ver",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud_ver" */ "@/views/acciones-movimientos/despidos/views/detalleSolicitud.vue" ),
      props: true
    },
    {
      path: "acciones-movimientos/despidos/solicitud/editar/:idSolicitud/:type?",
      name: "despido_personal_solicitud_editar",
      component: () =>
        import( /* webpackChunkName: "despido_personal_solicitud_editar" */ "@/views/acciones-movimientos/despidos/views/editarSolicitud.vue" ),
    },
    // {
    //   path: "acciones-movimientos/despidos/resolucion",
    //   name: "despido_personal_resolucion",
    //   component: () =>
    //     import( /* webpackChunkName: "despido_personal_solicitud" */ "@/views/acciones-movimientos/despidos/views/resolucionTribunal.vue" ),
    // },
    // {
    //   path: "acciones-movimientos/permuta/solicitud",
    //   name: "permuta_personal_solicitud",
    //   component: () =>
    //     import( /* webpackChunkName: "permuta_personal_crear" */ "@/views/acciones-movimientos/permutas/views/solicitudPermuta.vue" ),
    // },
    // {
    //   path: "acciones-movimientos/permuta/solicitud/ver/:id/:type?/:idSolicitud?/:id_unidad?/:id_est?",
    //   name: "permuta_personal_solicitud_ver",
    //   component: () =>
    //     import( /* webpackChunkName: "permuta_personal_crear" */ "@/views/acciones-movimientos/permutas/views/solicitudPermuta.vue" ),
    // },
    // {
    //   path: "acciones-movimientos/permuta/solicitud/:type/:idSolicitud?/:idEmpleado?",
    //   name: "permuta_personal_solicitud_crear",
    //   component: () =>
    //     import( /* webpackChunkName: "permuta_personal_crear" */ "@/views/acciones-movimientos/permutas/views/solicitudPermuta2.vue" ),
    // },
]