import { http_client } from "@/plugins/http_client";
// Tab 2
const obtenerIngresos = async (id_empleado_puesto) =>
  await http_client(`/api/ingreso/listado/${id_empleado_puesto}`);

const obtenerListaTipoIngresos = async () =>
  http_client("/api/ingreso/listado-general");

const guardarIngreso = async (params) =>
  http_client("/api/ingreso/crear-ingreso", params, "post");
// Tab 3
const obtenerDescuentos = async (id_empleado_puesto) =>
  await http_client(`/api/descuento/list/${id_empleado_puesto}`);

const obtenerListaDescuentos = async () => http_client("/api/deduccion/list");

const guardarDescuento = async (params) =>
  http_client("/api/deduccion_empleado_puesto/store", params, "post");

// Tab 4
const obtenerAportesPatronales = async (id_empleado_puesto) =>
  await http_client(`/api/aportes_patronales/list/${id_empleado_puesto}`);

export default {
  obtenerIngresos,
  obtenerListaTipoIngresos,
  guardarIngreso,
  obtenerDescuentos,
  obtenerListaDescuentos,
  guardarDescuento,
  obtenerAportesPatronales,
};
