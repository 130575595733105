<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="550">
      <template v-slot:activator="{}">
        <app-action-icon
          :icon="
            activo ? 'mdi-cancel' : 'mdi-checkbox-marked-circle-outline'
          "
          :tooltip="activo ? 'Desactivar' : 'Activar'"
          iconColor="iconsColor"
          @onClick="toggleModal"
        />
      </template>
      <v-card class="pa-5 rounded-xl">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn small icon text @click="toggleModal()">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="bluePrimary--text d-flex justify-center bolt py-0">
          <h3 class="title-add mb-5 text-center" style="font-size: 28px">
            {{ activo ? "Desactivar" : "Activar" }}
            <br v-if="$vuetify.breakpoint.xsOnly" />
            {{ title }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <span style="font-size: 16px; color: black"
                >¿Desea {{ activo ? "desactivar" : "activar" }}
                {{ combinedText(title) }}?</span
              >
              <br />
              <span style="font-size: 16px; color: black"
                >Se cambiará el estado de {{ combinedText(subtitle) }} a
                {{ activo ? 'Inactivo' : 'Activo' }}</span
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center mt-2 mb-6 py-0 ma-0">
          <v-btn
            rounded
            class="text-none ms-4"
            outlined
            color="bluePrimary"
            @click="toggleModal"
            style="padding-left: 20px; padding-right: 20px; font-weight: 900"
            :disabled="isLoading"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="bluePrimary"
            @click="
              () => {
                this.$emit('changeStatus');
                toggleModal();
              }
            "
            :loading="isLoading"
            class="text-none ms-4 text-white"
            rounded
            style="
              padding-left: 30px;
              padding-right: 30px;
              color: #ffffff !important;
            "
            variant="plain"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AppModalEstado",
  props: {
    activo: { type: Boolean, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, required: true },
    isPlural: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    combinedText(text) {
      const vocales = /[aeiouáéíóú]/i;
      if (this.isPlural) {
        return text.charAt(0).match(vocales) ? `las ${text}` : `los ${text}`;
      }
      return text.charAt(0).match(vocales) ? `el ${text}` : `la ${text}`;
    },

    isMaleWord(word) {
      const vocales = /[aeiouáéíóú]/i;
      return word.charAt(0).match(vocales);
    },
  },
};
</script>

<style></style>
