export default [
    {
        path: "gestion-planillas/reportes",
        name: "menu-reporte",
        component: () =>
          import("@/views/planilla/reporteria/views/MenuReportes.vue"),
    },
    {
        path: "gestion-planillas/reportes/dinamicos",
        name: "reportes-dinamicos",
        component: () =>
          import("@/views/planilla/reporteria/views/Dinamicos.vue"),
    },
    {
      path: "gestion-planillas/reportes/dinamicos/crear",
      name: "crear-reporte-dinamico",
      component: () =>
        import("@/views/planilla/reporteria/views/ReporteDinamico.vue"),
  },
    {
        path: "gestion-planillas/reportes/:id",
        name: "reporte",
        component: () =>
          import("@/views/planilla/reporteria/views/Personalizado.vue"),
    },
    {
      path: "gestion-planillas/reportes/dinamicos/:id",
      name: "reporte-dinamico",
      component: () =>
        import("@/views/planilla/reporteria/views/ReporteDinamico.vue"),
    }
]