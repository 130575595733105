export  default [
    {
        path: "/puesto-unidad",
        name: "PuestoUnidad",
        component: () =>
            import(/* webpackChunkName: "pais" */ "./index.vue"),
    },
    {
        path: "/mover-puesto-unidad",
        name: "mover-puesto",
        component: () => import(/* webpackChunkName: "puesto" */ "../puestoUnidad/tabs/puestoUnidad/MoverPuestoUnidad.vue")
    }
];
