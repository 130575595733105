export default [
    {
      path: "/gestion-roles",
      name: "gestion-roles",
      component: () =>
        import(
          /* webpackChunkName: "organigrama" */ "./roles/views/indexRol.vue"
        ),
    },
    {
      path: "/gestion-roles/crear",
      name: "gestion-roles-crear",
      component: () =>
        import(
          /* webpackChunkName: "organigrama" */ "./roles/views/formRol.vue"
        ),
    },
    {
      path: "/gestion-roles/editar/:id",
      name: "gestion-roles-editar",
      component: () =>
        import(
          /* webpackChunkName: "organigrama" */ "./roles/views/formRol.vue"
        ),
    },
    {
      path: "/gestion-roles/ver/:id",
      name: "gestion-roles-ver",
      component: () =>
        import(
          /* webpackChunkName: "organigrama" */ "./roles/views/formRol.vue"
        ),
    },
    {
      path: "/gestion-roles/historial/:id",
      name: "gestion-roles-historial",
      component: () =>
        import(
          /* webpackChunkName: "organigrama" */ "./roles/views/historialRol.vue"
        ),
    },
    {
        path: "asignar-roles",
        name: "asignar-roles",
        component: () =>
        import(/* webpackChunkName: "AsignarRoles" */ "@/views/gestionRoles/rolesEmpleados/components/AsignarRoles.vue"),
    },
    {
        path: "asignar-roles/:id",
        name: "asignar-roles-id",
        component: () =>import(/* webpackChunkName: "RolesEmpleados" */ "@/views/gestionRoles/rolesEmpleados/components/EditarAsignacion.vue"),
    }
];
