import { http_client } from "@/plugins/http_client";

//Catalogos - Descuentos
const getDescuentos = async (params = {}) => await http_client('/api/gestion-planilla/parametros/descuentos', params, "get")
const getIngresosPlanillas = async (params = {}) => await http_client(`/api/gestion-planilla/parametros/ingresos`, params, 'GET');
const getUnidades = async (params = {}) => await http_client(`/api/gestion-planilla/establecimiento/unidades`, params, 'GET');

//Catalogos - Otros Descuentos
const getBancos = async (params = {}) => await http_client(`/api/gestion-planilla/obtener-bancos`, params, 'GET');
const getAcreedores = async (params = {}) => await http_client(`/api/gestion-planilla/obtener-acreedores`, params, 'GET');
const getSobreIngreso = async (id, params = {}) => await http_client(`/api/gestion-planilla/${id}/ingresos`, params, 'GET');
const getFormaCalculos = async (params = {}) => await http_client(`/api/gestion-planilla/obtener-formas-calculo`, params, 'GET');

//Catalogos - Otros Descuentos nominas
const getBancosAcreedor = async (params = {}) => await http_client(`/api/gestion-planilla/descuentos/catalogos/banco`, params, 'GET');

//Descuentos de ley
const getDescuentosLey = async (id, params = {}) => await http_client(`/api/gestion-planilla/index-descuentos-ley/${id}`, params, 'GET');
const getDescuentoLey = async (id, params = {}) => await http_client(`/api/gestion-planilla/descuento-ley/${id}`, params, 'GET');
const addDescuentosLey = async (params) => await http_client(`/api/gestion-planilla/descuento-ley`, params, 'post')
const updateDescuentosLey = async (id, params) => await http_client(`/api/gestion-planilla/actualizar-descuento-ley/${id}`, params, 'put')
const deleteDescuentosLey = async (id) => await http_client(`/api/gestion-planilla/eliminar-descuento-ley/${id}`, {}, 'delete')

//Otros Descuentos de ley
const getOtrosDescuentosLey = async (id, params = {}) => await http_client(`/api/gestion-planilla/index-otros-descuentos/${id}`, params, 'GET');
const getOtroDescuentoLey = async (id, params = {}) => await http_client(`/api/gestion-planilla/obtener-otro-descuento/${id}`, params, 'GET');
const postOtrosDescuentosLey = async (params) => await http_client(`/api/gestion-planilla/otro-descuento`, params, 'post',{ headers: { "content-type": "multipart/form-data" } })
const putOtrosDescuentosLey = async (id, params) => await http_client(`/api/gestion-planilla/actualizar-otro-descuento/${id}`, params, 'post',{ headers: { "content-type": "multipart/form-data" } })

//Otro Descuentos Planilla Nomina
const getOtrosDescuentosNomina = async (id, params) => await http_client(`/api/gestion-planilla/descuentos/otro-descuento?id_nomina_planilla=${id}`, params, 'GET');
const getOtroDescuentoNomina = async (id, params = {}) => await http_client(`/api/gestion-planilla/descuentos/otro-descuento/${id}`, params, 'GET');
const postOtrosDescuentosNomina = async (params) => await http_client(`/api/gestion-planilla/descuentos/otro-descuento`, params, 'post',{ headers: { "content-type": "multipart/form-data" } })
const putOtrosDescuentosNomina = async (id, params) => await http_client(`/api/gestion-planilla/descuentos/otro-descuento/${id}`, params, 'post',{ headers: { "content-type": "multipart/form-data" } })
const deleteOtrosDescuentosNomina = async (id) => await http_client(`/api/gestion-planilla/descuentos/otro-descuento/${id}`, {}, 'delete')
const getDocOtroDescuentoNomina = async (id, params = {}) => await http_client(`/api/gestion-planilla/descuentos/otro-descuento/doc/${id}`, params, 'GET');

export default {
  getDescuentos,
  getIngresosPlanillas,
  getFormaCalculos,
  getUnidades,
  getBancos,
  getAcreedores,
  getSobreIngreso,
  getDescuentosLey,
  getDescuentoLey,
  addDescuentosLey,
  updateDescuentosLey,
  deleteDescuentosLey,
  getOtrosDescuentosLey,
  getOtroDescuentoLey,
  postOtrosDescuentosLey,
  putOtrosDescuentosLey,
  getBancosAcreedor,
  getOtrosDescuentosNomina,
  getOtroDescuentoNomina,
  postOtrosDescuentosNomina,
  putOtrosDescuentosNomina,
  deleteOtrosDescuentosNomina,
  getDocOtroDescuentoNomina
}
