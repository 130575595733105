const planillaRoutes = [
  {
    path: "/planilla",
    name: "planilla",
    component: () =>
      import(/* webpackChunckName: "planilla" */ "../Planilla.vue"),
  },
  {
    path: "/detalle-planilla/:id",
    name: "planillaDetalle",
    component: () =>
      import(
        /* webpackChunkName: "planillaDetalle" */ "../PlanillaDetalle.vue"
      ),
  },
  {
    path: "/planilla-empleado/:id",
    name: "planillaEmpleado",
    component: () =>
      import(
        /* webpackChunkName: "planillaEmpleado" */ "../PlanillaEmpleado.vue"
      ),
  },
];

export default planillaRoutes;
