<template>
  <div>
    <v-row justify="center">
      <v-expansion-panels accordion class="rounded-xl">
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header class="justify-start"><span class="font-weight-black text-h5"><v-icon
                color="#F3A723">mdi-alert-outline</v-icon>
              {{ unidad }}</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <p class="text-subtitle-1">{{ nombre }} {{ fecha }}</p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <p class="text-h6">
                  {{ observacion }}
                </p>
              </v-col>
            </v-row>
            <button-components :disabled="disabled" :colors="'#E4A90B'" :title="'Solventar'" class="mt-5"
              @btnAccion="showDialogSolventar = true" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <app-dialog-alert :dialogAlert="showDialogSolventar" :titulo="titulo" :mensaje="mensaje" btnAccionesTxt="Aceptar"
      btnAccionesTxtCancelar="Cancelar" :clasesTitulo="clasesTitulo" @dialogCerrarAlert="showDialogSolventar = false"
      @dialogAceptaralert="solventar" />
  </div>

</template>

<script>
import ButtonComponents from '../views/acciones-movimientos/components/ButtonComponents.vue';
import AppDialogAlert from "../views/acciones-movimientos/components/AppDialogAlert.vue";
export default {
  components: {
    ButtonComponents,
    AppDialogAlert
  },
  props: {
    unidad: {
      type: String,
      default: 'Unidad'
    },
    nombre: {
      type: String,
      default: 'Juan Perez'
    },
    fecha: {
      type: String,
      default: '01/02/2024 14:20'
    },
    observacion: {
      type: String,
      default: 'Observacion'
    }
  },
  data() {
    return {
      disabled: false,
      titulo: '¿Deseas solventar la solicitud?',
      mensaje: 'Al confirmar la acción no se podrá revertir los cambios',
      showDialogSolventar: false,
      clasesTitulo: {
        'font-weight': 'bold',
        'font-size': '20px',
        'text-align': 'center',
        color: '#1C1E4D',
        with: '100%'
      },
    }
  },
  methods: {
    solventar() {
      this.$emit('solventar')
      this.showDialogSolventar = false
      this.disabled = true
    }
  },
}
</script>

<style></style>