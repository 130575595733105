import { http_client } from "@/plugins/http_client";

const url = "/api/permuta/obtener";

const obtenerUnidades = async (filtros = {}) =>
  await http_client(`${url}/unidades`, filtros);

const obtenerLineasTrabajo = async (filtros = {}) =>
  await http_client(`${url}/linea-trabajo`, filtros);

const obtenerPlazas = async (filtros = {}) =>
  await http_client(`${url}/nombre-plaza`, filtros);

const obtenerInstituciones = async (filtros = {}) =>
  await http_client("api/instituciones", filtros);

const obtenerEstablecimientosPorInstitucion = async (filtros = {}) =>
  await http_client("api/establecimientos", filtros);

const obtenerEstablecimientoPorId = async (filtros = {}) =>
  await http_client("api/permuta/establecimiento", filtros);

const obtenerEmpleados = async (filtros = {}) =>
  await http_client("api/permuta/empleados", filtros);

const enviarSolicitud = async (data) => await http_client("api/permuta/v1/crear", data, "post", { headers: { "content-type": "multipart/form-data" } });

const modificarSolicitud = async (data) =>
  await http_client("api/permuta/modificar", data, "PUT");

const infoGlobal = async (filtros = {}) =>
  await http_client(
    "api/permuta/informacion/establecimiento-solicitante",
    filtros
  );

const ctlInstituciones = async (filtros = {}) =>
  await http_client("api/permuta/instituciones", filtros);

export default {
  obtenerUnidades,
  obtenerLineasTrabajo,
  obtenerPlazas,
  obtenerInstituciones,
  obtenerEstablecimientosPorInstitucion,
  obtenerEstablecimientoPorId,
  obtenerEmpleados,
  enviarSolicitud,
  modificarSolicitud,
  infoGlobal,
  ctlInstituciones,
};
