export default [
    {
        path: "/configuracion-parametros-sistema/",
        name: "configuracion-parametros-sistema",
        component: () =>
            import(/* webpackChunkName: "configuracionParametrosSistema" */ "./index.vue"),
    },
    {
        path: "/configuracion-parametros-sistema/0",
        name: "configuracion-parametros-sistema-0",
        component: () =>
            import(/* webpackChunkName: "configuracionParametrosSistema" */ "./index.vue"),
    },
    {
        path: "/configuracion-parametros-sistema/1",
        name: "configuracion-parametros-sistema-1",
        component: () =>
            import(/* webpackChunkName: "configuracionParametrosSistema" */ "./index.vue"),
    },
    {
        path: "/configuracion-parametros-sistema/2",
        name: "configuracion-parametros-sistema-2",
        component: () =>
            import(/* webpackChunkName: "configuracionParametrosSistema" */ "./index.vue"),
    },
    {
        path: "/configuracion-parametros-sistema/3",
        name: "configuracion-parametros-sistema-3",
        component: () =>
            import(/* webpackChunkName: "configuracionParametrosSistema" */ "./index.vue"),
    },
]