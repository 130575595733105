import {http_client} from "@/plugins/http_client";

const getMeses = async () => await http_client('/api/gestion-planilla/listar-meses', {}, "get")
const getYears = async () => await http_client('/api/gestion-planilla/listar-anios',{}, "get")
const getSistemas = async () => await http_client('/api/gestion-planilla/listar-sistema-pago',{}, "get")
const getTiposPlanilla = async () => await http_client('/api/gestion-planilla/listar-tipo-planilla',{}, "get")
const getPlanillas = async (params = {}) => await http_client('/api/gestion-planilla/index-apertura-planilla', params, "get")
const getEspecificoGastos = async (params = {}) => await http_client('/api/gestion-planilla/listar-especifico-gastos', params, "get")
const getIngresos = async (params = {}) => await http_client('/api/gestion-planilla/listar-ingresos', params, "get")
const createPlanilla = async (body) => await http_client('/api/gestion-planilla/apertura-planilla', body, "post")
const getPlanillaAperturada = async (params = {}) => await http_client('/api/gestion-planilla/index-apertura-planilla-detalle', params, "get")
const getIndexPlanilla = async (params = {}) => await http_client('/api/gestion-planilla/index-planilla', params, "get")
const getCategoriaDescuentos = async (params = {}) => await http_client('/api/gestion-planilla/descuentos/catalogos/categoria-descuento', params, "get")
const getTipoAcreedor = async (params = {}) => await http_client('/api/gestion-planilla/descuentos/catalogos/tipo-acreedor', params, "get")
const getAcreedor = async (params = {}) => await http_client('/api/gestion-planilla/descuentos/catalogos/acreedor', params, "get")
const getSobreIngresos = async (params = {}) => await http_client('/api/gestion-planilla/descuentos/catalogos/ingreso', params, "get")
const closePeriodoJustificacion = async (body = {}) => await http_client('/api/gestion-planilla/cerrar-justificacion', body, "put")
const openPeriodoJustificacion = async (body = {}) => await http_client('/api/gestion-planilla/observaciones/modificacion-apertura-justificacion', body, "post")
const obtenerJustificaciones = async (idPlanilla, params = {}) => await http_client(`/api/gestion-planilla/observaciones/modificacion-apertura-justificacion/${idPlanilla}`, params, "get")
const obtenerDocumentoJustificacion = async (idPlanilla, idJustificacion, params = {}) => await http_client(`/api/gestion-planilla/observaciones/modificacion-apertura-justificacion/${idPlanilla}/documento/${idJustificacion}`, params, "get", {}, true, "blob")
const closePlanilla = async (body = {}) => await http_client('/api/gestion-planilla/cerrar-planilla', body, "put")
const getDetallePlaza = async (params = {}) => await http_client('/api/gestion-planilla/obtener-detalle-plaza', params, "get")
const guardarEmpleadoComplementaria = async (body) => await http_client('/api/gestion-planilla/guardar-empleado-complementaria', body, "post")
const getEmpleadoComplementataria = async (params = {}) => await http_client('/api/gestion-planilla/obtener-empleado-complementaria', params, "get")
const getPlazaEmpleado = async (params = {}) => await http_client('/api/gestion-planilla/obtener-plaza-empleado', params, "get")
const getEstados = async (params = {}) => await http_client('/api/gestion-planilla/obtener-estados', params, "get")
const desbloquearNomina = async (id, params = {}) => await http_client(`/api/gestion-planilla/nomina/${id}/desbloquear`, params, "get")

//Ingresos de Ley
const getIngresosLey = async (body = {}) => await http_client(`/api/gestion-planilla/index-ingresos-planilla`, body, "get") //id_planilla body
const getEspecificoGasto = async () => await http_client(`/api/gestion-planilla/especifico-gasto`, {}, "get")
const getOtrosIngresos = async (body = {}) => await http_client(`/api/gestion-planilla/index-otros-ingresos`, body, "get")
const getFormaCalculo = async () => await http_client(`/api/gestion-planilla/formas-calculo`, {}, "get")
const getCifrados = async () => await http_client(`/api/gestion-planilla/cifrado-presupuestario`, {}, "get")
const getUnidades = async () => await http_client(`/api/gestion-planilla/establecimiento/unidades`, {}, "get")
const getListaIngresos = async (id) => await http_client(`/api/gestion-planilla/lista-ingresos/${id}`, {}, "get")

const modificarIngresosLey = async (body) => await http_client(`/api/gestion-planilla/modificar-ingreso-planilla`, body, "post")
const getOtroIngreso = async (id) => await http_client(`/api/gestion-planilla/get-otro-ingreso/${id}`, {}, "get")
const addOtroIngreso = async (body) => await http_client(`/api/gestion-planilla/agregar-otro-ingreso`, body, "post")
const deleteOtroIngreso = async (id) => await http_client(`/api/gestion-planilla/eliminar-otro-ingreso/${id}`, {}, "delete")
const updateOtroIngreso = async (id, body) => await http_client(`/api/gestion-planilla/actualizar-otro-ingreso/${id}`, body, "put")
const deleteEmpleadoComplementaria = async (body) => await http_client(`/api/gestion-planilla/eliminar-empleado-complementaria`, body, "delete")
const iniciarConfiguracion = async (params = {}) => await http_client(`/api/gestion-planilla/${params.type}`, params, "get")
const getEmpleadoConfigurar = async (id, params = {}) => await http_client(`api/gestion-planilla/get-empleado-configurar/${id}`, params, "get")
const finalizarConfiguracionNomina = async (body = {}) => await http_client(`api/gestion-planilla/finalizar-configuracion-nomina/${body.id}`, body, "put")
const estadoEditarNomina = async (body = {}) => await http_client(`api/gestion-planilla/estado-editar-nomina/${body.id}`, body, "put")
const obtenerAportesPatronales = async (id, params = {}) => await http_client(`api/gestion-planilla/obtener-aportes-patronales/${id}`, params, "get")
const actualizarEspecificoGasto = async (id, body = {}) => await http_client(`api/gestion-planilla/actualizar-especifico-gasto/${id}`, body, "put")
const obtenerCategoriasDescuentos = async () => await http_client(`api/gestion-planilla/obtener-categorias-descuentos`, {}, "get")
const categoriaDescuentoPlanilla = async (params) => await http_client(`api/gestion-planilla/categoria-descuento/planilla`, params, "get")
const ingresoEmpleadoDescuento = async (params) => await http_client(`api/gestion-planilla/ingreso-empleado/descuento`, params, "post")
const obtenerIngresosDeduccionLey = async (params) => await http_client(`api/gestion-planilla/obtener-ingresos-deduccion-ley/${params.id}/${params.type}`, params, "get")
const eliminarDescuenoIngreso = async (params) => await http_client(`api/gestion-planilla/eliminar-descuento-ingreso/${params.id}`, params, "delete")
const otroIngresoEmpleado = async (params) => await http_client(`api/gestion-planilla/otro-ingreso-empleado`, params, "post")

//Renta
const postRentaIngreso = async (id, params = {}) => await http_client(`/api/gestion-planilla/renta-ingreso-empleado/${id}`, params, 'post')
const deleteRentaIngreso = async (id) => await http_client(`/api/gestion-planilla/renta-ingreso-empleado/${id}`, {}, 'delete')
const eliminarOtroIngresoEmpleado = async (id) => await http_client(`/api/gestion-planilla/eliminar-otro-ingreso-empleado/${id}`, {}, 'delete')

const procesarEstadoPlanilla = async (params = {}) => await http_client(`/api/gestion-planilla/procesar-estado-planilla`, params, 'post')
const procesarEstadoPlanillaFD = async (body = {}) => await http_client(`/api/gestion-planilla/procesar-estado-planilla`, body, 'post', {
    headers: { "content-type": "multipart/form-data" },
})

//Cifrados
const listarCifrados = async (idPlanilla, params = {}) => await http_client(`/api/v1/planilla/${idPlanilla}/cifrados`, params, 'get')

const actualizarCifrados = async (idPlanilla, body = {}) => await http_client(`/api/v1/planilla/${idPlanilla}/cifrados`, body, 'put')

//Boleta de Pago
const verBoletaPago = async (id, params = {}) => await http_client(`/api/v1/planilla/nomina/boleta-pago/${id}/pdf`, params, 'get')

const enviarBoleta = async (id, params = {}) => await http_client(`/api/v1/planilla/nomina/boleta-pago/enviar/${id}`, params, 'post')

const enviarBoletas = async (id, params = {}) => await http_client(`/api/v1/planilla/nomina/boleta-pago/enviar-grupal/${id}`, params, 'post')
const boletasEnviadas = async (id, params = {}) => await http_client(`/api/v1/planilla/nomina/boleta-pago/boletas-enviadas/${id}`, params, 'get')

// Observaciones
const getObservaciones = async (idPlanilla, params = {}) =>
    await http_client(`/api/gestion-planilla/${idPlanilla}/observaciones`, params, 'get')

const descargarArchivo = async (params = {}, id_planilla_observacion) => {
    return await http_client(`api/gestion-planilla/observaciones/${id_planilla_observacion}/descargar`, params, "get", {}, true, "blob");
}

const archivosSafi = async (id, params = {}) => await http_client(`/api/v1/reporte-planilla/documentos-safi`, { id_planilla: id, ...params }, 'get');

export default {
    getMeses,
    getYears,
    getSistemas,
    getTiposPlanilla,
    getPlanillas,
    getEspecificoGastos,
    getIngresos,
    createPlanilla,
    getPlanillaAperturada,
    getCategoriaDescuentos,
    getTipoAcreedor,
    getAcreedor,
    getSobreIngresos,
    getIndexPlanilla,
    closePeriodoJustificacion,
    closePlanilla,
    getDetallePlaza,
    guardarEmpleadoComplementaria,
    getEmpleadoComplementataria,
    getPlazaEmpleado,
    getEstados,
    desbloquearNomina,
    getIngresosLey,
    getEspecificoGasto,
    getOtrosIngresos,
    getFormaCalculo,
    getCifrados,
    modificarIngresosLey,
    addOtroIngreso,
    deleteOtroIngreso,
    updateOtroIngreso,
    getUnidades,
    getListaIngresos,
    deleteEmpleadoComplementaria,
    iniciarConfiguracion,
    getEmpleadoConfigurar,
    finalizarConfiguracionNomina,
    estadoEditarNomina,
    obtenerAportesPatronales,
    actualizarEspecificoGasto,
    obtenerCategoriasDescuentos,
    categoriaDescuentoPlanilla,
    ingresoEmpleadoDescuento,
    obtenerIngresosDeduccionLey,
    eliminarDescuenoIngreso,
    getOtroIngreso,
    otroIngresoEmpleado,
    postRentaIngreso,
    deleteRentaIngreso,
    eliminarOtroIngresoEmpleado,
    procesarEstadoPlanilla,
    procesarEstadoPlanillaFD,
    verBoletaPago,
    enviarBoleta,
    enviarBoletas,
    getObservaciones,
    descargarArchivo,
    archivosSafi,
    openPeriodoJustificacion,
    obtenerJustificaciones,
    obtenerDocumentoJustificacion,
    listarCifrados,
    actualizarCifrados,
    boletasEnviadas
}
