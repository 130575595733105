import {http_client} from '@/plugins/http_client';

const getUnidadesOrganizativas = async (params) => await http_client('api/unidad', params, 'get');
const getEstructuraPlazas = async (params) => await http_client('api/v1/planilla/estructura-plaza', params, 'get');
const getDetallePlazas = async (params) => await http_client('api/v1/planilla/estructura-plaza/plaza', params, 'get');
//
const updateDetallePlaza = async (id_unidad_organizativa = 0, id_detalle_plaza = 0, body) =>
	await http_client(`api/v1/planilla/estructura-plaza/${id_unidad_organizativa}/${id_detalle_plaza} `, body, 'put');
//
const getDetallePlazasByPlaza = async (id_detalle_plaza = 0, params) =>
	await http_client(`api/v1/planilla/estructura-plaza/plaza/${id_detalle_plaza}`, params, 'get');
//
const getSubpartidasByPlazaAndUnidad = async (id_unidad_organizativa = 0, id_detalle_plaza = 0, params) =>
	await http_client(`api/v1/planilla/estructura-plaza/subpartida/${id_unidad_organizativa}/${id_detalle_plaza}`, params, 'get');
//
export default {
	getUnidadesOrganizativas,
	getEstructuraPlazas,
	getDetallePlazas,
	updateDetallePlaza,
	getDetallePlazasByPlaza,
	getSubpartidasByPlazaAndUnidad,
};
