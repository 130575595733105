import destacamientoRouter from "./destacamiento/destacamientoRouter";
import trasladosRouter from "./traslados/trasladosRouter"
import permutaRouter from "./permutas/permutaRouter";
import ascensosRouter from "./ascenso/ascensoRouter"
import aumentosRouter from "@/views/acciones-movimientos/aumentos/aumentosRouter";
import planillaRouter from './planilla/router/planillaRouter'
import listadoPlazasRouter from "./listado_plazas/listadoPlazasRouter";
import despidosRouter from "./despidos/despidosRouter";
export default [
  {
    path: "/acciones-movimientos",
    name: "acciones-movimientos",
    guard:'',
    component: () =>
      import(
        /* webpackChunkName: "rolesList" */ "./index.vue"
        ),
  },
  
  ...destacamientoRouter,
  ...trasladosRouter,
  ...permutaRouter,
  ...ascensosRouter,
  ...aumentosRouter,
  ...planillaRouter,
  ...listadoPlazasRouter,
  ...despidosRouter
]