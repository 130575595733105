import Vue from "vue";
import store from "../store";

const http_client = async (
    url,
    data = {},
    method = "get",
    headers = {},
    sendToken = true,
    responseType = "json",
    baseURL
) => {

    store.commit("setExpiracionToken");
    store.commit("setToken", localStorage.getItem("token"))
    if(store.state.expiracionToken){
        store.commit("setToken", null);
        //localStorage.clear();
    }
    const token = store.state.token
        ? store.state.token
        : localStorage.getItem("token");

    if (token && sendToken) headers.Authorization = "Bearer " + token;

    let config = {
        method,
        url,
        headers,
        responseType,
        baseURL
    };
    if (method === "get") {
        config.params = data;
    } else {
        config.data = data;
    }
    try {
        return await Vue.prototype.axios(config);
    } catch (e) {
        if (e.response.status === 500) {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.data.description ||
                    e?.response?.data?.errors?.message?.[0] ||
                    "Ha ocurrido un error interno",
                type: "error",
            });
        } else if (e.response.status === 422) {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.message ||
                    e.response.data.description ||
                    e.response.data.error ||
                    e?.response?.data?.errors?.message?.[0] ||
                    "No se pudo procesar la entidad",
                type: "error",
            });
        } else if (e.response.status === 404) {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.data.description ||
                    e?.response?.data?.errors?.message?.[0] ||
                    "No se encontró el recurso",
                type: "error",
            });
        } else if (e.response.status === 403) {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.data.description ||
                    e?.response?.data?.errors?.message?.[0] ||
                    e.response.data.error ||
                    "Petición rechazada",
                type: "error",
            });
        } else if (e.response.status === 400) {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.data.error ||
                    e.response.data.description ||
                    e?.response?.data?.errors?.message?.[0] ||
                    "Petición erronea",
                type: "error",
            });
        } else if (e.response.status === 401) {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.data.description ||
                    "Acceso no autorizado",
                type: "error",
            });
        } else {
            Vue.prototype.temporalAlert({
                show: true,
                message:
                    e.response.data.message ||
                    e.response.data.description ||
                    e?.response?.data?.errors?.message?.[0] ||
                    "Error al realizar la petición",
                type: "error",
            });
        }
        throw e;
    }
};

Vue.prototype.http_client = http_client;

export { http_client };
