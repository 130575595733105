import Vue from "vue";
import store from "../store";
import moment from "moment";

Vue.prototype.showLoader = async () => {
  await store.commit("utils/setLoader", true);
};

Vue.prototype.hideLoader = async () => {
  await store.commit("utils/setLoader", false);
};

Vue.prototype.temporalAlert = async (alert) => {
  await store.commit("utils/setAlert", alert);
};
Vue.prototype.hideLoader = async () => {
  await store.commit("utils/setLoader", false);
};

Vue.prototype.validateNotEmojis = (value)=>{
  if (value === null) return true;
  return value.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu) !== null ? false : true;
}

Vue.prototype.isDui = (dui) => {
  if (dui === null) return false;
  let valido = false;

  // Validando la longitud del numero de DUI
  if (dui.length === 10) {
    // Validando que no sea ceros todos los dígitos
    if (dui !== "00000000-0") {
      // Obteniendo los dígitos y el verificador
      let [digitos, validador] = dui.split("-");

      // Verficiando que la cadena
      if (typeof digitos !== "undefined" && typeof validador !== "undefined") {
        // Verificando que el validador sea de un solo caracter
        if (validador.length === 1) {
          // Convirtiendo los digitos a array
          digitos = digitos.split("");

          // Convirtiendo los datos a tipo integer
          validador = parseInt(validador, 10);
          digitos = digitos.map((digito) => parseInt(digito, 10));

          // Obteniendo la suma corresponiente
          let suma = digitos.reduce(
            (total, digito, index) => (total += digito * (9 - index)),
            0
          );

          // Obteniendo el Modulo base 10
          let mod = suma % 10;
          mod = validador === 0 && mod === 0 ? 10 : mod;

          let resta = 10 - mod;

          if (resta === validador) {
            valido = true;
          }
        }
      }
    }
  }
  return valido;
};

Vue.prototype.isNit = (nit) => {
  if (nit === null) return false;

  let valido = false;

  // Validando la longitud del numero de DUI
  if (nit.length === 17) {
    // Validando que no sea ceros todos los dígitos
    if (
      nit !== "0000-000000-000-0" &&
      nit.match(/^[0-9]{4}-[0-9]{6}-[0-9]{3}-[0-9]$/)
    ) {
      // Obteniendo los dígitos y el verificador por separado
      let [ubicacion, fecha, correlativo, validador] = nit.split("-");
      let digitos = (ubicacion + fecha + correlativo).split("");

      // Convirtiendo los datos a tipo integer
      validador = parseInt(validador, 10);
      correlativo = parseInt(correlativo, 10);
      let suma = 0;
      let mod = 0;
      if (correlativo <= 100) {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
          (total, digito, index) =>
            (total += parseInt(digito, 10) * (14 - index)),
          0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod === 10 ? 0 : mod;
      } else {
        // Obteniendo la suma corresponiente
        suma = digitos.reduce(
          (total, digito, index) =>
            (total +=
              parseInt(digito, 10) *
              parseInt(3 + 6 * Math.floor((index + 5) / 6) - (index + 1), 10)),
          0
        );
        // Obteniendo el Modulo base 11
        mod = suma % 11;
        mod = mod > 1 ? 11 - mod : 0;
      }
      if (mod === validador) {
        valido = true;
      }
    }
  }
  return valido;
};

Vue.prototype.getPaginationProperties = (response) => {
  return {
    page: Number(response.headers.page),
    per_page: Number(response.headers.per_page),
    total_rows: Number(response.headers.total_rows),
  };
};
//Funcion auxiliar para verificar paths accesibles
const buscarRuta = (rutas, ruta) => {
  return rutas.some((item) => {
    if (item?.childrens?.length > 0)
      if (buscarRuta(item?.childrens, ruta)) return true;

    return item.nombre_uri.toLowerCase() === ruta.name.toLowerCase();
  });
};
//Funcion para verificar las paths accesibles
Vue.prototype.canNext = async (ruta) => {
  //Rutas a las que el usuario siempre va a poder acceder
  const permit = [
    "Forbidden",
    "security",
    "login",
    "forgot-password",
    "reset-password",
    "2fa",
    "verifyMail",
    "Pais",
    "Institucion",
    "EstadoEmpleado",
    "Discapacidad",
    "TipoEstablecimiento",
    "Establecimiento",
    "Departamento",
    "EstadoCivil",
    "FormacionAcademica",
    "Municipio",
    "NivelEducativo",
    "Parentesco",
    "Sexo",
    "TipoDocumento",
    "TipoInstitucion",
    "TipoSangre",
    "InstitucionFinanciera",
    "Pagaduria",
    "TipoAcreedor",
    "Acreedor",
    "Formalizacion",
    "PresupuestoFinancia",
    "SistemaPago",
    "TipoFinanciamiento",
    "CategoriaPlaza",
    "EstadoPlaza",
    "PlazaNominal",
    "EstadoPuestoFuncional",
  ];
  if (!ruta.name) return false;
  //Si la ruta a ingresar esta definida en el arreglo local, permite ingreso
  if (permit.some((row) => row.toLowerCase() === ruta.name.toLowerCase()))
    return true;
  /*Se verifica si la ruta proporcionada se encuentra en las paths
    almacenadas, si no hay entonces se hace la petición para almacenarlas*/
  let { utils } = store.state;
  if (utils.rutas && utils.rutas.length === 0) {
    await store.dispatch("utils/getMenu");
  }
  //Busca ruta en el arreglo de paths
  return buscarRuta(utils.rutas, ruta);
};

Vue.prototype.FormatDatePrt = (date_param, time_param = false) => {
  if (date_param != null) {
    if (time_param) {
      return moment(date_param).format("DD/MM/YYYY HH:mm a");
    } else {
      return moment(date_param).format("DD/MM/YYYY");
    }
  } else {
    return "";
  }
};

Vue.prototype.FormatCurrencyPrt = (value, symbol) => {
  if (value != null) {
    return new Intl.NumberFormat("es-SV", {
      style: symbol ? "currency" : "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: "USD",
    }).format(value);
  } else {
    return "";
  }
};

/**
 * clousure para manejar la ejecución buscar (funcion antirebotes)
 * @param Function func
 * @param int timer
 * @return Function
 */
Vue.prototype.debounceTimer = (func, timer = 1000) => {
  let timeout = null;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func();
    }, timer);
  };
};
Vue.prototype.isValidaMessageError = ($el, nameClass = '.v-messages__message') => {
    const element = $el.querySelector(`${nameClass}:first-of-type`)
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    return;
  }
  
  
/**
* @method  preventInvalidCharacters
* @description prevenir digitos no permitidos, siempre que no sea la tecla de borrar o suprimir
* @param regex {RegExp} - expresion regular para validar los caracteres permitidos
* @param event {Event} - evento del input
 * @returns {void}
* **/
Vue.prototype.preventInvalidCharacters = (regex, event) => {
  if (!regex.test(event.key) && event.key !== 'Delete' && event.key !== 'Backspace') {
    event.preventDefault();
  }
}

/**
 *
 * @method countCharacters
 * @description limita la cantidad de caracteres en un input
 * @param event {Event} - evento del input
 * @param max {number} - cantidad de caracteres permitidos
 * @returns retorna el valor del input con la cantidad de caracteres permitidos
 */
Vue.prototype.countCharacters = (event, max) => {
  if (event.target.value.length > max) {
    return event.target.value = event.target.value.slice(0, max);
  }
}

/**
 * @method catalogo codigo fases destacamiento
 * @description catálogo de códigos de las diferentes fases de la solicitud destacamiento
 * @return Object
 */
Vue.prototype.destacamientoFase = Object.freeze({
  /**
   * @description Código para la fase UNIDADA: P3-F1
   */
  _UNIDADA: 'P3-F1',
  
  /**
   * @description Código para la fase JEFATURAA: P3-F2
   */
  _JEFATURAA: 'P3-F2',
  
  /**
   * @description Código para la fase RRHHA: P3-F3
   */
  _RRHHA: 'P3-F3',
  
  /**
   * @description Código para la fase TITULARA: P3-F4
   */
  _TITULARA: 'P3-F4',
  
  /**
   * @description Código para la fase UNIDADB: P3-F5
   */
  _UNIDADB: 'P3-F5',
  
  /**
   * @description Código para la fase JEFATURAB: P3-F6
   */
  _JEFATURAB: 'P3-F6',
  
  /**
   * @description Código para la fase RRHHB: P3-F7
   */
  _RRHHB: 'P3-F7',
  
  /**
   * @description Código para la fase TITULARB: P3-F8
   */
  _TITULARB: 'P3-F8',
});

/**
 * @method catalogo codigo fases de traslado
 * @description catálogo de códigos de las diferentes fases de la solicitud traslado
 * @return Object
 */
Vue.prototype.trasladoFase = Object.freeze({
  /**
   * @description Código para la fase UNIDADA: P7-F1
   */
  _UNIDADA: 'P7-F1',
  
  /**
   * @description Código para la fase JEFATURAA: P7-F2
   */
  _JEFATURAA: 'P7-F2',
  
  /**
   * @description Código para la fase RRHHA: P7-F3
   */
  _RRHHA: 'P7-F3',
  
  /**
   * @description Código para la fase TITULARA: P7-F4
   */
  _TITULARA: 'P7-F4',
  
  /**
   * @description Código para la fase UNIDADB: P7-F5
   */
  _UNIDADB: 'P7-F5',
  
  /**
   * @description Código para la fase JEFATURAB: P7-F6
   */
  _JEFATURAB: 'P7-F6',
  
  /**
   * @description Código para la fase RRHHB: P7-F7
   */
  _RRHHB: 'P7-F7',
  
  /**
   * @description Código para la fase TITULARB: P7-F8
   */
  _TITULARB: 'P7-F8',
});