import catalogos from './catalogos'
import cargos from './cargos'
import empleados from './empleados'
import solicitud from './solicitud'
import traslados from './traslados'
import ascenso from './ascenso'
import acreedor from './acreedor'
import despidos from './despidos'
import documento from './documento'

export default {
  catalogos,
  cargos,
  empleados,
  solicitud,
  traslados,
  ascenso,
  acreedor,
  despidos,
  documento
}