export default {
  namespaced: true,
  state: {
    items:[],
    dialogEmpleado: false,
    itemEmpleado: [],
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    setModel(state, payload) {
      state.model = payload;
    },
    setDialogEmpleado(state, payload) {
      state.dialogEmpleado = payload;
    },
    setItemEmpleado(state, payload) {
      state.itemEmpleado = payload;
    },
  },
};
