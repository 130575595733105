import { render, staticRenderFns } from "./AppActionIcon.vue?vue&type=template&id=d56e6268&scoped=true"
import script from "./AppActionIcon.vue?vue&type=script&lang=js"
export * from "./AppActionIcon.vue?vue&type=script&lang=js"
import style0 from "./AppActionIcon.vue?vue&type=style&index=0&id=d56e6268&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d56e6268",
  null
  
)

export default component.exports