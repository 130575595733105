import { http_client } from "@/plugins/http_client";

const obtenerCategories = async (page,params = {}) =>
  await http_client(`/api/v1/planilla/categoria-descuento?page=${page}`, params, "get");
const crearCatedoria = async (params = {}) =>
  await http_client(`/api/v1/planilla/categoria-descuento/crearCategoriaDescueto`, params, "post");
const actualizarCategoriaDescuento = async (idDescuento, params = {}) =>
  await http_client(
    `/api/v1/planilla/categoria-descuento/actualizarCategoriaDescueto/${idDescuento}`,
    params,
    "put"
  );

export default {
  obtenerCategories,
  crearCatedoria,
  actualizarCategoriaDescuento,
};
