<template>
  <section>
    <!-- table -->
    <v-data-table
      :item-class="() => 'v-data-table-row'"
      :headers="headers"
      :items-per-page="per_page"
      :items="items"
      :loading="loading"
      class="elevation-4 rounded-xl"
      hide-default-footer
    >
      <!-- slots -->
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" v-if="name"></slot>
      </template>
    </v-data-table>

    <!-- pagination -->
    <template>
      <v-row class="mt-7 mx-4">
        <v-col cols="12" class="d-flex justify-center align-center">
          <button-components
            :disabled="page === 1 || totalRows === 0"
            :title="'Anterior'"
            :width-size="'fit-content'"
            class="px-1 mt-5"
            text
            :ripple="false"
            :plain="true"
            @btnAccion="page--"
          />

          <v-pagination
            :total-visible="totalVisibleCd"
            color="#1C1E4D"
            class="mt-4"
            v-model="page"
            :length="pagesCd ?? 0"
          />

          <button-components
          :disabled="page === pagesCd || totalRows === 0"
            :title="'Siguiente'"
            :width-size="'fit-content'"
            class="px-1 mt-5"
            text
            :ripple="false"
            :plain="true"
            @btnAccion="page++"
          />
        </v-col>
      </v-row>
    </template>
  </section>
</template>

<script>
import ButtonComponents from "../views/acciones-movimientos/components/ButtonComponents.vue";
export default {
  components: { ButtonComponents },
  name: "AppTableIngresosLeyComponent",

  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },

    ctlPerPage: {
      type: Array,
      default: () => [5, 10, 25, 100],
    },

    totalRows: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data: () => ({
    page: 1,
    per_page: 10,
  }),

  computed: {
    pagesCd() {
      return Math.ceil(this.totalRows / this.per_page);
    },

    totalVisibleCd() {
      const screens = [
        { device: this.$vuetify.breakpoint.smAndUp, visible: 10 },
        { device: this.$vuetify.breakpoint.xsOnly, visible: 5 },
      ];
      const screen = screens.find((screen) => screen.device);
      return screen.visible;
    },
  },

  methods: {
    CalcSizeFn(width) {
      const widthScreen = this.$vuetify.breakpoint.width;
      return widthScreen <= width;
    },

    // express functions
    refresh() {
      this.$emit("refresh", {
        page: this.page,
        per_page: this.per_page,
      });
    },
    reset() {
      this.page = 1;
      this.per_page = 10;
    },
  },

  watch: {
    page(value) {
      this.$emit("refresh", {
        page: value,
        per_page: this.per_page,
      });
    },
    per_page(value) {
      this.page = 1;
      this.$emit("refresh", {
        page: this.page,
        per_page: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-pagination__item {
  border-radius: 12px !important;
  width: auto !important;
  height: auto !important;
  display: block !important;
  font-size: 16px;
  margin: 5px 5px;
  padding: 3px 3px;
  min-width: auto;
  
}

.custom-select {
  max-width: min-content;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.pagination button {
  color: black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 3px 7px;
  margin: 0 5px;
}

.pagination button:hover {
  background-color: #1c1e4d;
  color: #fff;
}

.pagination button.active {
  background-color: #1c1e4d;
  border-radius: 5px;
  color: #fff;
  cursor: default;
}

.pagination button:disabled {
  pointer-events: none;
  opacity: 0.6;
}

::v-deep .v-data-table-header th {
  color: #000 !important;
}

::v-deep .v-data-table-row {
  &:nth-child(even) {
    background-color: #ffffff;
  }

  &:nth-child(odd) {
    background-color: #e0e0e0;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 15px;
      }
      &:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }
}

::v-deep .v-data-table-row:hover {
  background-color: #bdbdbd !important;
  color: #fff;

  &:first-child {
    td {
      &:first-child {
        border-top-left-radius: 0px !important;
      }
      &:last-child {
        border-top-right-radius: 0px !important;
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 15px !important;
      }
      &:last-child {
        border-bottom-right-radius: 15px !important;
      }
    }
  }
}

::v-deep .v-data-table-header
{
  th {
    font-size: 14px !important;
  }
}

.v-data-table > .v-data-table__wrapper > table {
  min-width: 20vw;
}

//Responsive
@media (max-width: 600px) {
  ::v-deep .v-data-table-header th {
    font-size: 12px !important;
  }

  ::v-deep .v-data-table-row {
    td {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 400px) {
  ::v-deep .v-data-table-header th {
    font-size: 10px !important;
  }

  ::v-deep .v-data-table-row {
    td {
      font-size: 10px !important;
    }
  }
}

::v-deep .v-btn__content {
  color: #000;
}

::v-deep .v-pagination__navigation {
  display: none;
}
</style>
<style scoped>
::v-deep .v-data-table .v-data-table__wrapper .v-data-table__row:hover {
  background-color: transparent !important;
}
</style>