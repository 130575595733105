const reportesRouter = [
  {
    path: "/reporte/plazas",
    name: "reporte-plazas",
    component: () => import(/* webpackChunkName: "renuncias" */ "./views/plazas.vue"),
  },
  {
    path: "/reporte/generales",
    name: "reporte-generales",
    component: () => import(/* webpackChunkName: "MenuReportesGenerales" */ "./views/MenuReportesGenerales.vue"),
  },
  {
    path: "/reporte/generales/:tipoReporte",
    name: "ver-reporte",
    component: () => import(/* webpackChunkName: "reportes-generales" */ "./views/ReporteGeneral.vue"),
  },
  {
    path: "/reporte/generales/d/:tipoReporte",
    name: "ver-reporte-doble",
    component: () => import(/* webpackChunkName: "reportes-generales-doble" */ "./views/ReporteGeneralDoble.vue"),
  },
  {
    path: "/reporte/generales/costos/:tipoReporte",
    name: "ver-reporte-doble-costos",
    component: () => import(/* webpackChunkName: "reportes-generales-doble-costos" */ "./views/ReporteGeneralDobleCostos.vue"),
  }
];

export default reportesRouter;