

import {http_client} from "@/plugins/http_client";

const getPeticiones = async (params = {}) => await http_client('/api/permuta/peticiones', params)
const getPersonalPermutas = async (params = {}) => await http_client(`/api/permuta/persona/permutar`, params)
const getPersonalSolicita = async (params = {}) => await http_client(`/api/permuta/persona/solicita`, params)
const getInfoEmpleado = async (params = {}) => await http_client(`/api/permuta/informacion-empleado`, params)
// verifica si se muestran el personal propuesto:
const getPersonalPropuesto = async (params = {}) => await http_client(`/api/permuta/persona/verifica`, params)
const deleteEmpleadoPropuesto = async (params) => await http_client(`/api/permuta/propuesta/eliminar`, params, 'delete')
const empleadoPropuesto = async (params) => await http_client(`/api/permuta/propuesta`, params, 'put')
const empleadoPropuestoModificar = async (params) => await http_client(`/api/permuta/propuesta/modificar`, params, 'put')
// Documentos
const postDocumentos = async (params) => await http_client(`/api/permuta/guardar-documento`, params, 'post', { headers: { "content-type": "multipart/form-data" },})
const getDocumentos = async (id_solicitud) => await http_client(`/api/permuta/obtener-documento/${id_solicitud}`)
const deleteDocumentos = async (id, filtros) => await http_client(`/api/permuta/eliminar-documento/${id}`, filtros, 'delete')
const verPdfDocumentos = async (id) => await http_client(`/api/permuta/ver-documento/${id}`)
const descargarDocumentos = async (id) => await http_client(`/api/permuta/descargar-documento/${id}`)

//descarga pdf en cada tab
const descargarPdf = async (params) => await http_client(`/api/permuta/v1/descargar-pdf`, params)

//obtener fase
const obtenerFase = async (params) => await http_client(`/api/permuta/obtener/fase-permuta`, params)

//acciones de fase
const accionesFase = async (params) => await http_client(`/api/permuta/v1/aceptar`, params, 'put')
const observarFase = async (params) => await http_client(`/api/permuta/justificar`, params, 'post')
const denegarPermuta = async (params) => await http_client(`/api/permuta/denegar`, params, 'post')
// Autorizaciones
const getAutorizaciones = async (params = {}) => await http_client('/api/permuta/autorizaciones', params)

//Solicitudes
const getSolicitudes = async (params = {}) => await http_client('/api/permuta/solicitudes', params)

//info usuario
const infoEstablecimiento = async (params = {}) => await http_client('/api/permuta/informacion/establecimiento-solicitante', params)
const infoEstablecimientoAumento = async (params = {}) => await http_client('/api/v1/aumento/informacion/establecimiento-solicitante', params)
const infoEstablecimientoRenuncia = async (params = {}) => await http_client('/api/renuncia/informacion/establecimiento-solicitante', params)

// info usuario 2
const info = async (params = {}) => await http_client('/api/permuta/observar-justificar', params)

const getInfoEmpleadoPermuta = async (params = {}) => await http_client(`/api/permuta/informacion-empleado-permuta`, params)

// Enviar fecha a permuta
const cambiarFechasPermutas = async (params) => await http_client(`/api/permuta/cambiar-fecha`, params, 'put')

export default {
    getPeticiones,
    getPersonalPermutas,
    getPersonalSolicita,
    getInfoEmpleado,
    getPersonalPropuesto,
    deleteEmpleadoPropuesto,
    empleadoPropuesto,
    empleadoPropuestoModificar,
    postDocumentos,
    getDocumentos,
    deleteDocumentos,
    verPdfDocumentos,
    descargarDocumentos,
    getAutorizaciones,
    getSolicitudes,
    obtenerFase,
    descargarPdf,
    accionesFase,
    observarFase,
    infoEstablecimiento,
    infoEstablecimientoAumento,
    infoEstablecimientoRenuncia,
    info,
    getInfoEmpleadoPermuta,
    cambiarFechasPermutas,
    denegarPermuta,
}
