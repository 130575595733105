import { http_client } from "@/plugins/http_client";

function getParams(params) {
  return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}

const listar = ({ params, data }) =>
  http_client(
    `/api/ctl_especifico_gasto/search?` + getParams(params),
    data,
    "post"
  );
const actualizar = (body) =>
  http_client(`/api/ctl_especifico_gasto/${body.id}`, body, "patch");

const desactivar = (body) =>
  http_client(`/api/ctl_especifico_gasto/${body.id}`, {}, "delete");

const crear = (body) => http_client(`/api/ctl_especifico_gasto`, body, "post");

const cargarArchivo = (archivo) =>
  http_client("/api/importar/especifico-gasto", archivo, "post", {
    headers: { "content-type": "multipart/form-data" },
  });

export default {
  listar,
  actualizar,
  desactivar,
  crear,
  cargarArchivo,
};
