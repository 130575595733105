<template>
    <v-dialog v-model="show" width="100%" overlay-opacity="100" overlay-color="#000" persistent>
        <v-container fluid>
            <v-card color="black">
                <v-btn icon @click="cerrar">
                    <v-icon size="35px" color="white">mdi-keyboard-backspace</v-icon>
                </v-btn>
                <embed :src="archivoBlob" type="application/pdf" width="100%" style="height: 80vh" />

            </v-card>
        </v-container>
    </v-dialog>
</template>

<script>

export default {

    props: {
        show: {
            type: Boolean,
            default: false
        },
        archivo: null,
    },
    data() {
        return {
            archivoBlob: null,
        }
    },
    methods: {
        cerrar() {
            this.$emit('cerrar');
        },
        conversion(dataURI) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            let byteString = atob(dataURI.split(",")[1]);

            // separate out the mime component
            let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

            // write the bytes of the string to an ArrayBuffer
            let ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            let ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            let blob = new Blob([ab], { type: mimeString });
            return blob;
        }
    },
    created() {
        this.archivoBlob = URL.createObjectURL(
            this.conversion(this.archivo)
        );


    }
}
</script>