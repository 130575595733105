const ingresosRoutes = [
  {
    path: "ingresos",
    name: "ingresos",
    component: () => import("../Ingresos.vue"),
  },
  {
    path: "/visualizar-ingreso/:id",
    name: "visualizar",
    component: () => import("../components/VisualizarIngresoComponent.vue"),
  },
  {
    path: "agregar-ingreso",
    name: "agregar",
    component: () => import("../components/AgregarIngresoComponent.vue"),
  },
  {
    path: "/editar-ingreso/:id",
    name: "editar",
    component: () => import("../components/EditarIngresoComponent.vue"),
  }
];

export default ingresosRoutes;
