const renunciasRouter = [
  {
    path: "/acciones-movimientos/renuncias",
    name: "renuncias",
    component: () => import(/* webpackChunkName: "renuncias" */ "./index.vue"),
  },
  {
    path: "/acciones-movimientos/renuncias/detalle/empleado/:id_persona/renuncia/:id_renuncia/empleado-puesto/:id_empleado_puesto",
    name: "renuncias.detalle",
    component: () => import(/* webpackChunkName: "renuncias" */ "./views/detalle.vue"),
  }
];

export default renunciasRouter;