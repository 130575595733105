import { http_client } from "@/plugins/http_client";

const obtenerPlanillas = {
  listadoPlanilla: async (params) =>
    await http_client("api/planilla/detalle/listado", params),
};

const obtenerPagaduria = {
  listPagaduria: async () =>
    await http_client("api/planilla/list/pagaduria"),
};

const crearPlanilla = {
  storePlanilla: async (body) =>
    await http_client("api/planilla/crear-planilla", body, "post"),
};

const obtenerTipoPlanilla = {
  listTipoPlanilla: async () =>
    await http_client(`api/planilla/list/tipo-planilla`),
};

const obtenerPlanillaID = {
  planillaDetalle: async (id) => await http_client(`api/planilla/${id}`),
};

const obtenerDetallePlanilla = async (id_planilla, filtros) =>
  await http_client(`/api/planilla/${id_planilla}`, filtros);

const obtenerDetalleEmpleado = async (id_planilla_detalle) =>
  http_client(`/api/planilla/detalle_empleado/${id_planilla_detalle}`);

const generarReportePlanilla = async () =>
  http_client('api/planilla/pdf/reporte-planillas-pdf');

const generarReportePlanillaDetalle = async (id) =>
  http_client(`api/planilla/pdf/reporte-planilla-pdf/${id}`)

export default {
  obtenerPlanillas,
  obtenerPagaduria,
  crearPlanilla,
  obtenerTipoPlanilla,
  obtenerPlanillaID,
  obtenerDetallePlanilla,
  obtenerDetalleEmpleado,
  generarReportePlanilla,
  generarReportePlanillaDetalle
};
