import { http_client } from "@/plugins/http_client";
const url = 'api/v1/reporte';
const url_v2 = 'api/reportes';
const url_v3 = 'api/v1/reporte-general' 
const get = async ( params = {} ) => await http_client(`${url}`, params);
const getById = async (id) => await http_client(`${url}/${id}`);

const getDataReport = async (id) => await http_client(`${url_v2}/show/${id}`);
const postReport = async (id, data) => await http_client(`${url_v3}/${id}`, data, "post");
const getExecutionReport = async (id_solicitud) => await http_client(`${url_v3}/solicitud-ejecucion`, id_solicitud, "get");
const getDataGeneratedReport = async (filtros, id) => await http_client(`${url_v3}/datos/${id}`, filtros, "get");
const updateDataGeneratedReport = async (id) => await http_client(`${url_v3}/datos/${id}`, {}, "put");
const deleteDataGeneratedReport = async (id) => await http_client(`${url_v3}/datos/${id}`, {}, "delete");
const getFiltersReport = async (id) => await http_client(`${url_v3}/usuario/${id}`);
const getExcelReport = async (id, data) => await http_client(`${url_v3}/datos/reporte/${id}`, data, "post", {}, true, "blob");

export default {
	get,
	getById,
	getDataReport,
	postReport,
	getExecutionReport,
	getDataGeneratedReport,
	updateDataGeneratedReport,
	deleteDataGeneratedReport,
	getFiltersReport,
	getExcelReport
}