import { http_client } from "@/plugins/http_client";

const obtenerCargos = async (params = {}) =>
  http_client("api/filtrar-cargo", params, "get");
const obtenerDetallesCargos = async (params = {}) =>
  http_client("api/cargo-funcional", params, "get");

const obtenerActividad = async (params = {}) =>
  http_client("api/listar-actividad", params, "get");
const obtenerFormacion = async (params = {}) =>
  http_client("api/listar-formacion", params, "get");
const obtenerConocimiento = async (params = {}) =>
  http_client("api/listar-conocimiento", params, "get");
const obtenerExperiencia = async (params = {}) =>
  http_client("api/listar-experiencia", params, "get");
const obtenerCapacidad = async (params = {}) =>
  http_client("api/listar-capacidad", params, "get");
const obtenerAspecto = async (params = {}) =>
  http_client("api/listar-aspecto", params, "get");
const cargosPendientes = async (id_solicitud, params = {}) =>
  http_client(
    `api/destacamiento/listar-detalles-cargo-pendientes/${id_solicitud}`,
    params,
    "get"
  );

  const cargosPendientesTraslado = async (id_solicitud, params = {}) =>
    http_client(
      `api/traslado/listar-detalles-cargo-pendientes/${id_solicitud}`,
      params,
      "get"
    );
const asingnarCargos = async (idDetalleCargo, params = {}) =>
  http_client(
    `api/destacamiento/asignar-unidad-cargo/${idDetalleCargo}`,
    params,
    "post"
  );

  const asingnarCargosTraslado = async (idDetalleCargo, params = {}) =>
    http_client(
      `api/traslado/asignar-unidad-cargo/${idDetalleCargo}`,
      params,
      "post"
    );

const eliminarUnidadCargo = async (id, params ={}) => 
  http_client(
    `/api/destacamiento/eliminar/asignacion-unidad-cargo/${id}`,
    params,
    "delete"
  );

  const eliminarUnidadCargoTraslado = async (id, params ={}) => 
    http_client(
      `/api/traslado/eliminar/asignacion-unidad-cargo/${id}`,
      params,
      "delete"
    );

const eliminarCargoAsignado = async ( params = {} ) =>
  http_client(
    'api/destacamiento/eliminar-cargo-asignado',
     params,
     "delete"
  )

  const eliminarCargoAsignadoTraslado = async ( params = {} ) =>
    http_client(
      'api/traslado/eliminar-cargo-asignado',
       params,
       "delete"
    )

const listarDetallesCargosAsignados = async (idDetalleCargo, params = {}) =>
  http_client(
    `api/destacamiento/listar-unidades-asignadas/${idDetalleCargo}`,
    params,
    "get"
  );

  const listarDetallesCargosAsignadosTraslado = async (idDetalleCargo, params = {}) =>
    http_client(
      `api/traslado/listar-unidades-asignadas/${idDetalleCargo}`,
      params,
      "get"
    );
const unidadesAsignadasCargos = async (id_solicitud, params = {}) =>
  http_client(
    `api/destacamiento/listar-detalles-cargo-asignados/${id_solicitud}`,
    params,
    "get"
  );

  const unidadesAsignadasCargosTraslado = async (id_solicitud, params = {}) =>
    http_client(
      `api/traslado/listar-detalles-cargo-asignados/${id_solicitud}`,
      params,
      "get"
    );

const asignarPersonaACargo = async (idSolicitud, params= {}) =>
  http_client(
    `api/destacamiento/asignar-persona-cargo/${idSolicitud}`,
    params,
    "post"
  )

  const asignarPersonaACargoTraslado = async (idSolicitud, params= {}) =>
    http_client(
      `api/traslado/asignar-persona-cargo/${idSolicitud}`,
      params,
      "post"
    )

  const eliminarPersonaACargo = async (idSolicitud, params= {}) =>
    http_client(
      `api/destacamiento/asignar-persona-cargo/${idSolicitud}`,
      params,
      "delete"
    )

    const eliminarPersonaACargoTraslado = async (idSolicitud, params= {}) =>
      http_client(
        `api/traslado/asignar-persona-cargo/${idSolicitud}`,
        params,
        "delete"
      )

  const denegarCargoSolicitado = async (idDetalle, params = {}) =>
    http_client(
      `api/destacamiento/denegar-cargo-solicitado/${idDetalle}`,
      params,
      "put"
    )

  const activarDesactivarCargo = async (idDetalle, params ={}) =>
    http_client(
      `api/destacamiento/activar-cargo/${idDetalle}`,
      params,
      "put"
    )

    const activarDesactivarCargoTraslado = async (idDetalle, params ={}) =>
      http_client(
        `api/traslado/activar-cargo/${idDetalle}`,
        params,
        "put"
      )

const cargosAsignadosDetalles = async (id_solicitud, idUnidad, fase, params = {}) =>
  http_client(
    `api/destacamiento/detalles-cargo-unidad/${id_solicitud}/${idUnidad}/${fase}`,
    params,
    "get"
  );

  
const cargosAsignadosDetallesTraslado = async (id_solicitud, idUnidad, fase, params = {}) =>
  http_client(
    `api/traslado/detalles-cargo-unidad/${id_solicitud}/${idUnidad}/${fase}`,
    params,
    "get"
  );

const cargosAsignarPersona = async (id_solicitud, idUnidad, fase, bandera, params = {}) =>
  http_client(
    `api/destacamiento/detalles-cargo-asignar/${id_solicitud}/${idUnidad}/${fase}/${bandera}`,
    params,
    "get"
  );

  const cargosAsignarPersonaTraslado = async (id_solicitud, idUnidad, fase, bandera, params = {}) =>
    http_client(
      `api/traslado/detalles-cargo-asignar/${id_solicitud}/${idUnidad}/${fase}/${bandera}`,
      params,
      "get"
    );
export default {
  obtenerCargos,
  obtenerDetallesCargos,
  obtenerActividad,
  obtenerFormacion,
  obtenerConocimiento,
  obtenerExperiencia,
  obtenerCapacidad,
  obtenerAspecto,
  cargosPendientes,
  asingnarCargos,
  eliminarUnidadCargo,
  listarDetallesCargosAsignados,
  unidadesAsignadasCargos,
  asignarPersonaACargo,
  cargosAsignarPersona,
  cargosAsignadosDetalles,
  eliminarPersonaACargo,
  denegarCargoSolicitado,
  eliminarCargoAsignado,
  activarDesactivarCargo,
  cargosAsignadosDetallesTraslado,
  unidadesAsignadasCargosTraslado,
  cargosPendientesTraslado,
  asingnarCargosTraslado,
  eliminarUnidadCargoTraslado,
  listarDetallesCargosAsignadosTraslado,
  eliminarCargoAsignadoTraslado,
  cargosAsignarPersonaTraslado,
  activarDesactivarCargoTraslado,
  eliminarPersonaACargoTraslado,
  asignarPersonaACargoTraslado
};
