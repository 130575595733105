<template>
  <v-dialog v-model="show" max-width="900" persistent>
    <v-card class="pa-4">
      <v-card-title
        :class="'text-h3 text-no-wrap px-4 d-flex justify-center primary--text'">
        <p :class="$vuetify.breakpoint.smAndUp ? 'text-h3' : 'custom_title'">{{ title }}</p>
      
      </v-card-title>
      <v-card-text class="text-center"  >
        <slot></slot>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn class="text-none" outlined rounded color="bluePrimary darken-1" @click="close">
          Cancelar
        </v-btn>
        <v-btn
          color="bluePrimary text-none"
          rounded
          class="white--text ml-4"
          @click="confirm"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmDialogComponent",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>
<style scoped>
.v-card {
  border-radius: 50px;
}

@media screen and (min-width: 280px){
  .custom_title{
    font-size: 16px
  }

}



</style>
