export default {
    namespaced: true,
    state: {
       idReporte: null,
       showResumenTable: false,
       showEmpleadoTable: false,
       //Empleados
        empleadoPanels: null,
       checkboxesIngresosEmpleado: [],
       checkboxesDescuentos: [],
       descuentosLey: false,
       ordenesDescuentos: false,
       checkboxesCategorias: [],
       checkboxesCategoriasAcreedores: [],
       checkboxesAcreedores: [],
       checkboxesDepartamentos: [],
       checkboxesAlcaldias: [],
       //Resumen
       resumenPanels: null,
       checkboxesIngresosResumen: [],
       nombreTab: null
    },
    getters: {
       
    },
    mutations: {
        setIdReporte(state, payload) {
            state.idReporte = payload
        },
        setShowResumenTable(state, payload) {
            state.showResumenTable = payload
        },
        setShowEmpleadoTable(state, payload) {
            state.showEmpleadoTable = payload
        },
        setCheckboxesIngresosEmpleado(state, payload) {
            state.checkboxesIngresosEmpleado = payload
        },
        setCheckboxesDescuentos(state, payload) {
            state.checkboxesDescuentos = payload
        },
        setDescuentosLey(state, payload) {
            state.descuentosLey = payload
        },
        setOrdenesDescuentos(state, payload) {
            state.ordenesDescuentos = payload
        },
        setCheckboxesCategorias(state, payload) {
            state.checkboxesCategorias = payload
        },
        setCheckboxesCategoriasAcreedores(state, payload) {
            state.checkboxesCategoriasAcreedores = payload
        },
        setCheckboxesIngresosResumen(state, payload) {
            state.checkboxesIngresosResumen = payload
        },
        setEmpleadoPanels(state, payload) {
            state.empleadoPanels = payload
        },
        setResumenPanels(state, payload) {
            state.resumenPanels = payload
        },
        setCheckboxesAcreedores(state, payload) {
            state.checkboxesAcreedores = payload
        },
        setCheckboxesDepartamentos(state, payload) {
            state.checkboxesDepartamentos = payload
        },
        setCheckboxesAlcaldias(state, payload) {
            state.checkboxesAlcaldias = payload
        },
        setNombreTab(state, payload) {
            state.nombreTab = payload
        }
    },
    actions: {
      
    },
}