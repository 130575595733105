
export default {
    namespaced: true,
    state: {
       flagPeticiones: false,

    },
    getters: {
       
    },
    mutations: {
       setFlagPeticiones(state, flag){
           state.flagPeticiones = flag;
       }
        
    },
    actions: {
      
    },
};


