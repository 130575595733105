<template>
  <main>
    <v-dialog
      v-model="dialogAlert"
      :width="width"
      persistent
    >
      

      <v-card>
        <v-card-title >
         <!-- <app-title :title="titulo" /> -->
         <div class="justify-center mt-7" style="width: 100%;">
          <p :style="clasesTitulo" style="word-break: break-word">{{ titulo }}</p>
         </div>
       
        </v-card-title>

        <v-card-text>
         <div class="text-center font-weight-medium" style="color: black;">
          {{ mensaje }}
         </div>
         <div class="text-center font-weight-medium" style="color: black;">
          {{ message2 }}
         </div>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-col class="d-flex justify-md-center flex-md-row flex-column align-center" cols="12">
            <button-components
              :colors="'1C1E4D'"
              :outlined="true"
              :title="btnAccionesTxtCancelar"
              @btnAccion="dialogCerrar"
            />

            <button-components
              :colors="colorBtntAccion"
              :title="btnAccionesTxt"
              :outlined="btnOutlined"
              :disabled="disabled"
              class="ml-md-5 mt-4 mt-md-0"
              @btnAccion="dialogAceptar"
            />
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import AppTitle from './AppTitle.vue'
import ButtonComponents from './ButtonComponents.vue';
export default {
  components: { AppTitle, ButtonComponents},
  props:{
    width: {
      type: String,
      default: '650',
      required: false
    },
    titulo: {
        type: String,
        required: true
    },
    dialogAlert: {
        type: Boolean,
        default: false
    },
    mensaje: {
      type: String,
      required: true
    },
    message2: {
      type: String,
      
    },
    btnAccionesTxt: {
      type: String,
      required: true
    },
    clasesTitulo: {
      type: Object,
      required: true
    },
    btnAccionesTxtCancelar: {
      type: String,
      required: true
    },
    colorBtntAccion:{
      type: String,
      default:'#1C1E4D'
    },
    btnOutlined: {
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  methods:{
    dialogCerrar(){
      this.$emit('dialogCerrarAlert');
    },
    dialogAceptar(){
      this.$emit('dialogAceptaralert');
    }
  }

}
</script>

<style>

</style>