<template>
  <v-app-bar app :elevation="0" color="white" height="70">
    <v-app-bar-nav-icon v-show="showButton" @click.stop="setSideBar(!sideBar)" />
    <!--v-show="$vuetify.breakpoint.mdAndDown"-->
    <v-spacer></v-spacer>
    <div class="mt-3 d-flex align-center">
      <v-menu rounded offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
              <v-btn depressed class="px-6" v-bind="attrs" v-on="{ ...on, ...tooltipOn }">
                <span class="caption grey--text text--darken-2">
                  {{ (userInfo?.user?.email || "Invitado").slice(0, 14) }}
                </span>
                <v-icon size="32" color="primary" class="ml-4">
                  mdi-account-circle
                </v-icon>
                <v-icon right size="28" color="grey darken-1">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <span>{{ userInfo?.user?.email || "Invitado" }}</span>
          </v-tooltip>
        </template>

        <v-list dense nav>
          <!-- <v-list-item link to="/profile">
        <v-list-item-icon>
          <v-icon size="20" color="primary">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="body-2"> Perfil </v-list-item-title>
          </v-list-item> -->

          <v-list-item href="https://admin-auth-rh.egob.sv/menu">
            <v-list-item-icon>
              <v-icon size="20" color="primary">mdi-menu</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="body-2">
              Menú principal
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="cerrarSession">
            <v-list-item-icon>
              <v-icon size="20" color="primary">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="body-2">
              Cerrar sesión
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import authServices from "../services/auth.services";
export default {
  name: "AppHeader",
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setSideBar", "setMenu", "setRoles"]),
    ...mapMutations(["setToken"]),
    async cerrarSession() {
      // console.log("cerrarSession");
      let tokenIdentidad = localStorage.getItem("identidadToken");
      let token = localStorage.getItem("token");
      this.setMenu([]);
      this.setToken(null);
      this.setRoles([]);
      localStorage.removeItem("roles");
      try {
        const response = await authServices.logout(token, tokenIdentidad);
        localStorage.clear();
        this.$router.push({ name: "login" }).catch((e) => { });
      } catch (e) {
        this.$store.commit("utils/setLoader", false);
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("utils", {
      sideBar: "sideBar",
    }),
  },
};
</script>

<style scoped>
.circular-container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #1c1e4d;
  /* Fondo azul */
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>
