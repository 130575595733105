export default [
  {
    path: "acciones-movimientos/destacamiento",
    name: "destacamiento_personal",
    component: () =>
      import( /* webpackChunkName: "destacamiento_personal" */ "@/views/acciones-movimientos/destacamiento/index.vue" ),
  },
  {
    path: "acciones-movimientos/destacamiento/solicitud/:id?",
    name: "destacamiento_personal_solicitud",
    component: () =>
      import( /* webpackChunkName: "destacamiento_personal_crear" */ "@/views/acciones-movimientos/destacamiento/views/solicitudDestacamiento.vue" ),
  },
  {
    path: "acciones-movimientos/destacamiento/solicitud/ver/:id",
    name: "destacamiento_personal_solicitud_ver",
    component: () =>
      import( /* webpackChunkName: "destacamiento_personal_crear" */ "@/views/acciones-movimientos/destacamiento/views/solicitudDestacamiento.vue" ),
  },
  {
    path: "acciones-movimientos/destacamiento/peticiones/:id?",
    name: "destacamiento_personal_peticiones",
    component: () =>
      import( /* webpackChunkName: "destacamiento_personal_crear" */ "@/views/acciones-movimientos/destacamiento/views/solicitudDestacamiento.vue" ),
  },
  {
    path: "acciones-movimientos/destacamiento/autorizacion/:id?",
    name: "destacamiento_personal_autorizaciones",
    component: () =>
      import( /* webpackChunkName: "destacamiento_personal_crear" */ "@/views/acciones-movimientos/destacamiento/views/solicitudDestacamiento.vue" ),
  },
]