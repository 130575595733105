export default [
    {
        path: "acciones-movimientos/traslados",
        name: "traslados",
        component: () =>
            import(
                /* webpackChunkName: "rolesList" */ "./index.vue"
                ),
    },
    {
        path: "acciones-movimientos/traslados/solicitud/:id?",
        name: "trasladosCreate",
        component: () =>
            import(
                /* webpackChunkName: "rolesCreate" */ "./views/solicitudTraslado.vue"
                ),
    },
    {
        path: "acciones-movimientos/traslados/solicitud/edit/:id",
        name: "trasladosEdit",
        component: () =>
            import(
                /* webpackChunkName: "rolesCreate" */ "./views/solicitudTraslado.vue"
                ),
    },
    {
        path: "acciones-movimientos/traslados/solicitud/view/:id",
        name: "trasladosView",
        component: () =>
            import(
                /* webpackChunkName: "rolesCreate" */ "./views/solicitudTraslado.vue"
                ),
    },
    {
        path: "acciones-movimientos/traslados/peticiones/:id",
        name: "trasladoPeticiones",
        component: () =>
            import(
                /* webpackChunkName: "rolesCreate" */ "./views/solicitudTraslado.vue"
                ),
    },
    {
        path: "acciones-movimientos/traslados/autorizaciones/:id",
        name: "trasladoAutorizaciones",
        component: () =>
            import(
                /* webpackChunkName: "rolesCreate" */ "./views/solicitudTraslado.vue"
                ),
    },
]