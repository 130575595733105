export default [
  {
    path: 'acciones-movimientos/aumentos',
    name: 'aumentos',
    component: () => import('@/views/acciones-movimientos/aumentos/indexAumentos.vue')
  },
  {
    path: 'acciones-movimientos/aumentos/solicitud',
    name: 'aumentos_solicitud',
    component: () => import('@/views/acciones-movimientos/aumentos/views/solicitudAumentoSalarial.vue')
  },
  {
    path: 'acciones-movimientos/aumentos/ver-solicitud/:id/:id_empleado_puesto',
    name: 'aumento_ver',
    component: () => import('@/views/acciones-movimientos/aumentos/views/detalleAumentoSalarial.vue')
  },
]