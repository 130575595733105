import { http_client } from "@/plugins/http_client";
// Tab 1: Tarifas
const obtenerTarifas = async (page) =>
  await http_client(`/api/tarifas-salario/listado?page=` + page);

const obtenerNombreSector = async () =>
  await http_client(`/api/ctl_tipo_tarifa_salario/index`);

const obtenerHistoricoTarifas = async (id, page) =>
  await http_client(
    `/api/tarifas-salario/tipo-salario-minimo/${id}?page=${page}`
  );

const crearListaTipoIngresos = async (params) =>
  http_client("/api/tarifas-salario/crear", params, "post");

const CambiarEstadoListaTipoIngreso = async (id_stado) =>
  http_client(`api/tarifas-salario/cambiar-estado/${id_stado}`, {}, "put");

const actualizarTarifaSalario = async (id, data) =>
  http_client(`api/tarifas-salario/editar/${id}`, data, "put");
// v2
const getListTarifas = async (body) => await http_client(`/api/v1/planilla/tarifas-salario-minimo`, body);
const getTarifaById = async (id) => await http_client(`/api/v1/planilla/tarifas-salario-minimo/ver-tarifa/${id}`);
const postSalarioMin = async (body) => await http_client(`/api/v1/planilla/tarifas-salario-minimo/crear-tarifa`, body, 'post');
const putDataSalario = async (id, body) => await http_client(`/api/v1/planilla/tarifas-salario-minimo/editar-tarifa/${id}`, body, 'put');
const putStatusSalario = async (id, body) => await http_client(`/api/v1/planilla/tarifas-salario-minimo/actualizar-estado/${id}`, body, 'put');

// Tab 2: Formas De Pago
const obtenerFormasDePago = async (body) =>
  await http_client(`/api/sistema-pago/listado`, body);

const ActivarDesactivarFormasDePago = async (id) =>
  await http_client(
    `/api/sistema-pago/cambiar-estado/${id}`,
    {},
    "put"
  );

const EditarFormasDePago = async (id, param) =>
  await http_client(`/api/sistema-pago/editar/${id}`, param, "put");

const CrearFormasDePago = async (param) =>
  await http_client(`/api/sistema-pago/crear`, param, "post");

const buscarFormaPago = async (name) =>
  await http_client(`/api/sistema-pago/buscar?nombre=` + name);

const calculoRentaList = async () => await http_client("/api/sistema-pago/listado-calculo-renta");

// Tab 3 Descuentos
const obtenerDescuentos = async (body) =>
  await http_client("/api/v1/planilla/descuentos", body);

const obtenerCategoriaDescuento = async (body) => 
  await http_client("/api/v1/planilla/descuentos/obtener-categoria-descuento", body);

const guardarDescuento = async (body) =>
  await http_client(`/api/v1/planilla/descuentos/crear-descuento`, body, "post");

const obtenerEspGasto = async (body) =>
  await http_client(`/api/listado/especifico-gasto`, body);

const obtenerAcreedor = async (body) => await http_client(`/api/ctl_acreedor`, body);

const obtenerListadoDeIngresos = async (body) => await http_client("/api/v1/planilla/ingresos/ver-base-ingreso", body);

const buscarAcreedor = async (id) =>
  await http_client(`/api/nomina/buscar-acreedor?id=` + id);

const activarDesactivarDescuento = async (id, data) =>
  await http_client(`/api/v1/planilla/descuentos/actualizar-estado/${id}`, data, "put");

const gastosEspecificosDescuentos = async (id, page) =>
  await http_client(
    `/api/descuentos-ley/listado-especifico/${id}?page=${page}`,
    {},
    "get"
  );


const ObtenerUltimoDescuento = async (id) =>
  await http_client(`/api/descuentos-ley/ultimo-descuento/${id}`, {}, "get");

const obtenerDetalleDescuentoPorId = async (id, body) => await http_client(`/api/v1/planilla/descuentos/ver-descuento/${id}`, body);

const actualizarDescuento = async (data) => await http_client("/api/v1/planilla/descuentos/editar-descuento", data, "post");
// Tab 4 Renta
const obtenerRenta = async (page, filtro) =>
  await http_client(`/api/v1/planilla/renta/listado`, {page, filtro}, "get");

const ActivarDesactivarRenta = async (id) =>
  await http_client(`/api/renta/cambiar-estado/${id}`, {}, "put");

const obtenerRentaDetalle = async (id) =>
  await http_client(`/api/renta/listado/detalle/${id}`);

const getCodigoRetencion = async (tipo) => await http_client('/api/v1/planilla/obtener-codigo-parametros', tipo, 'get')

//Listado de tipo tarifa (sector) en agregar salario mínimo

const obtenerTipoTarifas = async () =>
  await http_client(`/api/ctl_tipo_tarifa_salario/index`);

//endpoints varios
const GetEmpleado = async (id) =>
  await http_client(`/api/nomina/id-empleado/${id}`, {}, "get");

const consultarFormacionAcademica = async (param) =>
  await http_client(`/api/ctl_formacion_educativa/search`, param, "post");

// Sistema de pagos

const obtenerGastosEspecificos = async (page) =>
  await http_client(`/api/establecimiento_especifico_gasto`, {}, "GET");

// Ingresos

const obtenerIngresos = async (body) =>
  await http_client('/api/v1/planilla/ingresos', body);

const obtenerFormaIngresos = async () =>
  await http_client(`/api/v1/planilla/forma-calculada`, {}, "GET");

const obtenerBaseIngresos = async () =>
  await http_client(`/api/v1/planilla/ingresos/ver-base-ingreso`, {}, "GET");


const crearIngreso = async (param) =>
  await http_client(`/api/v1/planilla/ingresos/agregar-ingreso`, param, "POST");

const ActualizarEstadoIngreso = async (id) =>
  await http_client(`/api/v1/planilla/ingresos/estado-ingreso/${id}`, {}, "PUT");

const ActualizarIngreso = async (id, param) =>
  await http_client(`/api/v1/planilla/ingresos/editar-ingreso/${id}`, param, "PUT");

const obtenerDisposicionesTransitorias = async (page) =>
  await http_client(
    `/api/disposicion-transitoria/listado?page=${page}`,
    {},
    "GET"
  );
const obtenerTipoDisposicion = async () =>
  await http_client(`api/remuneracion_descuento/listado`, {}, "GET");
const obtenerTipoIngreso = async () =>
  await http_client(`/api/tipo-ingreso/listado`, {}, "GET");
const obtenerTipoIngresoFiltrado = async () =>
  await http_client(`api/tipo-ingreso/listado-filtrado`, {}, "GET");
const obtenerDescuentosActivos = async () =>
  await http_client(`/api/ctl_tipo_descuento`, {}, "GET");
const obtenerDescuentosDeLey = async () =>
  await http_client(`/api/descuentos-ley/listado-descuento`, {}, "GET");
const obtenerTramosRentaActiva = async () =>
  await http_client(`/api/renta/listado-tramos`, {}, "GET");
const obtenerDeduccion = async () =>
  await http_client(`api/deduccion/listado`, {}, "GET");
const obtenerTramosActivos = async () =>
  await http_client(`/api/renta/listado-tramos`, {}, "GET");
const obtenerDescuentoSobre = async () =>
  await http_client(`/api/descuentos_sobre/list`, {}, "GET");
const obtenerInstituciones = async () =>
  await http_client(`/api/ctl_institucion`, {}, "GET");
const obtenerDetalleDisposicionTransitoria = async (id) =>
  await http_client(`/api/disposicion-transitoria/mostrar/${id}`, {}, "GET");
const enviarDispocicionesTrancitorias = async (param) =>
  await http_client(`/api/disposicion-transitoria/crear`, param, "POST", {
    "Content-Type": "multipart/form-data",
  });

  // Tab Disposiociones
  const getTiposDisposiciones = async () => await http_client(`/api/v1/planilla/disposiciones-transitorias/list-tipos-disposicion`);
  const getTiposIngreso = async () => await http_client(`/api/v1/planilla/disposiciones-transitorias/list-tipos-ingreso`);
  const getDescuentoLey = async () => await http_client(`/api/v1/planilla/disposiciones-transitorias/list-descuento-ley`);
  const getDetallesDescLey = async (id) => await http_client(`/api/v1/planilla/disposiciones-transitorias/detalle-descuento-ley/${id}`);
  const getDetallesRenta = async (id) => await http_client(`/api/v1/planilla/disposiciones-transitorias/detalle-tramo-renta/${id}`);
  const getInstituciones = async () => await http_client(`/api/v1/planilla/disposiciones-transitorias/list-instituciones`);
  const getTipoTramoRenta = async () => await http_client(`/api/v1/planilla/disposiciones-transitorias/list-tipo_tramo_renta`);
  const getDisposiciones = async (page, filtro) => await http_client(`/api/v1/planilla/disposiciones-transitorias`, page, filtro, );
  const postGuardarDisposicion = async (params) => await http_client(`/api/v1/planilla/disposiciones-transitorias/store`, params, "POST");
  const getDetalleRegistro = async (id) => await http_client(`api/v1/planilla/disposiciones-transitorias/show/${id}`);
  const getDetalleRegistroInstituciones = async (id) => await http_client(`api/v1/planilla/disposiciones-transitorias/show-instituciones/${id}`);
  const getListGastos = async () => await http_client(`/api/listado/especifico-gasto`);
  const getObtCateDescuento = async () => await http_client(`/api/listado/especifico-gasto`);
  const getTramoRenta = async (id) => await http_client(`/api/v1/planilla/disposiciones-transitorias/list-tramos-renta/${id}`);


  const obtenerCodigoParametros = async (tipo) => await http_client(`/api/v1/planilla/obtener-codigo-parametros`, tipo);

export default {
  obtenerTarifas,
  obtenerNombreSector,
  obtenerHistoricoTarifas,
  EditarFormasDePago,
  ActivarDesactivarFormasDePago,
  CrearFormasDePago,
  activarDesactivarDescuento,
  crearListaTipoIngresos,
  gastosEspecificosDescuentos,
  CambiarEstadoListaTipoIngreso,
  obtenerFormasDePago,
  buscarFormaPago,
  obtenerRenta,
  ActivarDesactivarRenta,
  obtenerTipoTarifas,
  guardarDescuento,
  obtenerEspGasto,
  obtenerAcreedor,
  buscarAcreedor,
  GetEmpleado,
  consultarFormacionAcademica,
  obtenerDisposicionesTransitorias,
  obtenerTipoDisposicion,
  obtenerTipoIngreso,
  obtenerDescuentosActivos,
  obtenerDescuentosDeLey,
  obtenerTramosRentaActiva,
  obtenerDeduccion,
  obtenerTramosActivos,
  obtenerDescuentoSobre,
  obtenerRentaDetalle,
  obtenerInstituciones,
  obtenerDetalleDisposicionTransitoria,
  enviarDispocicionesTrancitorias,
  obtenerTipoIngresoFiltrado,
  actualizarTarifaSalario,
  ObtenerUltimoDescuento,
  
  // v2 tarifa salario minimo
  getListTarifas,
  getTarifaById,
  postSalarioMin,
  putStatusSalario,
  putDataSalario,

  // Sistema de pagos
  obtenerGastosEspecificos,
  calculoRentaList,

  //Ingresos
  obtenerIngresos,
  obtenerFormaIngresos,
  obtenerBaseIngresos,
  crearIngreso,
  ActualizarEstadoIngreso,
  ActualizarIngreso,

  // Disposición transitoria
  getTiposDisposiciones,
  getTiposIngreso,
  getDescuentoLey,
  getDetallesDescLey,
  getDetallesRenta,
  getInstituciones,
  getTipoTramoRenta,
  getDisposiciones,
  postGuardarDisposicion,
  getTramoRenta,
  // Descuentos
  obtenerDescuentos,
  obtenerCategoriaDescuento,
  guardarDescuento,
  obtenerEspGasto,
  obtenerAcreedor,
  obtenerListadoDeIngresos,
  activarDesactivarDescuento,
  obtenerDetalleDescuentoPorId,
  actualizarDescuento,
  getDetalleRegistro,
  getDetalleRegistroInstituciones,
  getListGastos,
  getObtCateDescuento,

  obtenerCodigoParametros,
  getCodigoRetencion
};
