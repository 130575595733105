

import {http_client} from "@/plugins/http_client";

const getAumentos = async (params = {}) => await http_client('/api/v1/aumento/solicitudes', params)
const getPersona = async (params = {}) => await http_client('/api/v1/aumento/busqueda-persona', params)
const getSelectPlazas  = async (params = {}) => await http_client('/api/v1/aumento/plaza', params)
const getPlazaDetalle = async (params = {}) => await http_client('/api/v1/aumento/detalle-plaza', params)
const postAumentoSolicitud = async (params) => await http_client('/api/v1/aumento/crear', params, 'post',{ headers: { "content-type": "multipart/form-data" } })
const verSolicitudAumento = async (params) => await http_client(`/api/v1/aumento/detalle-solicitud`, params)

const postDocumentos = async (params) => await http_client(`/api/v1/aumento/guardar-documento`, params, 'post', { headers: { "content-type": "multipart/form-data" },})
const getDocumentos = async (id_solicitud) => await http_client(`/api/v1/aumento/obtener-documento/${id_solicitud}`)   
const deleteDocumentos = async (id, filtros) => await http_client(`/api/v1/aumento/eliminar-documento/${id}`, filtros, 'delete')
const verPdfDocumentos = async (id) => await http_client(`/api/v1/aumento/ver-documento/${id}`)
const descargarDocumentos = async (id) => await http_client(`/api/v1/aumento/descargar-documento/${id}`)


const getUnidades = async (params = {}) => await http_client('/api/v1/aumento/obtener/unidades', params)    
const getLineaTrabajo = async (params = {}) => await http_client('/api/v1/aumento/obtener/linea-trabajo', params)
const getTituloPlaza = async (params = {}) => await http_client('/api/v1/aumento/obtener/nombre-plaza', params)
const getPlaza = async (params = {}) => await http_client('/api/v1/aumento/obtener-nueva-plaza', params)

const aceptarSolicitud = async (params) => await http_client('/api/v1/aumento/aceptar', params, 'put')
const observarSolicitud = async (params) => await http_client('/api/v1/aumento/observar', params, 'put')

const getFase = async (params = {}) => await http_client('/api/v1/aumento/fase', params)

const obtenerObservaciones = async (params) => await http_client(`/api/v1/aumento/observar-justificar`, params)
export default {
    getAumentos,
    getPersona,
    getSelectPlazas,
    getPlazaDetalle,
    postAumentoSolicitud,
    verSolicitudAumento,
    postDocumentos,
    getDocumentos,
    deleteDocumentos,
    verPdfDocumentos,
    descargarDocumentos,
    getUnidades,
    getLineaTrabajo,
    getTituloPlaza,
    getPlaza,
    aceptarSolicitud,
    observarSolicitud,
    getFase,
    obtenerObservaciones,
}