import {http_client} from "../plugins/http_client";

const obtenerHistoricoRenta = async (idNominaPlanilla, params = {}) => await http_client(`/api/renta/historico/${idNominaPlanilla}`, params, 'GET');
const crearHistoricoRenta = async (body) => await http_client('/api/renta/historico', body, 'post', { headers: { "content-type": "multipart/form-data" } });
const aplicarRentaMensual = async (body) => await http_client('/api/renta/aplicar-renta-mensual', body, 'put');

export default {
    obtenerHistoricoRenta,
    crearHistoricoRenta,
    aplicarRentaMensual,
}