import {http_client} from '@/plugins/http_client';

const URL = '/api/gestion-planilla';

const listadoDocumentos = async (id_planilla, page) => {
    return await http_client(`${URL}/${id_planilla}/documentos/index?page=${page}`);
}

const guardarDocumento = async (id_planilla, data={}) => {
    return await http_client(`${URL}/${id_planilla}/documentos/store`, data, "post");
}

const verDocumento = async (id_planilla, id_documento) => {
    return await http_client(`${URL}/${id_planilla}/documentos/${id_documento}`, {
        // responseType: 'blob'
    });
}

const eliminarDocumento = async (id_planilla, id_documento, data) => {
    return await http_client(`${URL}/${id_planilla}/documentos/${id_documento}`, data,"delete");
}

const guardarDocumentoPlanillaObservacion = async (id_planilla, data={}) => {
    return await http_client(`${URL}/${id_planilla}/observaciones/store`, data, "post");
}
export default {
    listadoDocumentos,
    guardarDocumento,
    verDocumento,
    eliminarDocumento,
    guardarDocumentoPlanillaObservacion
}