export default {
    namespaced: true,
    state: {
        aplica_renta: false,
    },
    mutations: {
        setAplicaRenta(state, payload) {
            state.aplica_renta = payload
        },
    },
}