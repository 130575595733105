export default {
	namespaced: true,
	state: {
		info_sistema_pago: {},
	},
	getters: {
		titulo: (state) => state.info_sistema_pago.sistema_pago,
		anio: (state) => state.info_sistema_pago.anio,
	},
	mutations: {
		setInfoSistemaPago(state, info) {
			state.info_sistema_pago = info;
		},
	},
	actions: {
		updateInfoSistemaPago({commit}, info) {
			commit('setInfoSistemaPago', info);
		},
	},
};
