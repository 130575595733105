import jwtDecode from "jwt-decode";

export default {
    namespaced: true,
    state: {
       capacidadesSelect:[],
       actividadSelect:[],
       otrosAspectosSelec:[],
       formacionSelect:[],
       conocimientoSelect:[],
       experienciaSelect:[],
       dataSaved:[],
       doumentsSolicitud:[],
       dataSolicitud:{},
       mntActivarBtnEnviar:true,
       mntActivarBtnDenegar:false,
       mntActivarBtnObservar:false,
       empleado:{},
       id_plaza_empleado:null,
       catiadadHoras:null,
       institucionData:{},
       establecimientoData:{},
       unidadData:{},
       llamarAsignados: false,
       llamarPendientes: false,
       lastPageAsignados: null,
       establecimientoSolicitado: null,
       solventarDisabled: false,
       activo: true,
       estadoSolicitud: null,
       verEnObservacion: false,
       borradorDisabled: false,
       showForm: false,

    },
    getters: {

    },
    mutations: {
        setCapacidadesSelect(state,payload){
            state.capacidadesSelect = payload
        },
        setactividadSelect(state,payload){
            state.actividadSelect = payload
        },
        setotrosAspectosSelec(state,payload){
            state.otrosAspectosSelec = payload
        },
        setformacionSelect(state,payload){
            state.formacionSelect = payload
        },
        setconocimientoSelect(state,payload){
            state.conocimientoSelect = payload
        },
        setexperienciaSelect(state,payload){
            state.experienciaSelect = payload
        },
        setdataSaved(state,payload){
            state.dataSaved = [...state.dataSaved,payload]
        },
        setDocumentSolicitud(state,payload){
            state.doumentsSolicitud = [...payload]
        },
        setdataSolicitud(state,payload){
            state.dataSolicitud = payload
        },
        setMntActivarBtnEnviar(state,payload){
            state.mntActivarBtnEnviar = payload
        },
        setMntActivarBtnDenegar(state, payload) {
            state.mntActivarBtnDenegar = payload
        },
        setMntActivarBtnObservar(state, payload) {
            state.mntActivarBtnObservar = payload
        },
        setEmpleado(state,payload){
            state.empleado = payload
        },
        setIdPlazaEmpleado(state,payload){
            state.id_plaza_empleado = payload
        },
        setCatidadHoras(state,payload){
            state.catiadadHoras = payload
        },
        setInstitucionData(state,payload){
            state.institucionData = payload
        },
        setEstablecimientoData(state,payload){
            state.establecimientoData = payload
        },
        setUnidadData(state,payload){
            state.unidadData = payload
        },
        setLlamarAsignados(state, payload) {
            state.llamarAsignados = payload
        },
        setLlamarPendientes(state, payload){
            state.llamarPendientes = payload
        },
        setLastPage(state, payload) {
            state.lastPageAsignados = payload
        },
        setEstablecimientoSolicitado(state, payload) {
            state.establecimientoSolicitado = payload
        },
        setSolventarDisabled(state, payload) {
            state.solventarDisabled = payload
        },
        setActivo(state, payload) {
            state.activo = payload
        },
        setEstadoSolicitud(state,payload) {
            state.estadoSolicitud = payload
        },
        setVerEnObservacion(state, payload) {
            state.verEnObservacion = payload
        },
        setBorradorDisabled(state, payload){
            state.borradorDisabled = payload
        },
        setShowForm(state, payload) {
            state.showForm = payload
        }
    },
    actions: {
        getSolicitanteData:({commit}, dataSolicitante = null)=> {
            if (dataSolicitante) {
                commit('setInstitucionData',dataSolicitante.institucion);
                commit('setEstablecimientoData',dataSolicitante.establecimiento);
                commit('setUnidadData',dataSolicitante.unidad);
             }
           else{
                const tokenData = jwtDecode(localStorage.getItem('token'));

                commit('setInstitucionData',tokenData?.puesto?.institucion);
                commit('setEstablecimientoData',tokenData?.puesto?.establecimiento);
                commit('setUnidadData',tokenData?.puesto?.unidad_organizativa);

            }

        }
    },
};
