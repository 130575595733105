<template>
    <v-menu v-model="menuFechaInicial" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
        offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined dense label="Fecha de inicio" v-model="addForm.fecha_inicio"
                prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly></v-text-field>
        </template>
        <v-date-picker v-model="fechaInicial" @input="menuFechaInicial = false" locale="es" format="DD-MM-YYYY"
            v-on="fechaInicialFormat"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    data(){
        return {
            fechaView: null,
            fechaFormat: null,
        }
    },
    computed:{
        fechaInicialFormat(){
            if(this.addForm.fecha_inicio != null){
                let fechaDDMMAAAA = moment(this.addForm.fecha_inicio).format("DD-MM-YYYY");
                this.addForm.fecha_inicio = fechaDDMMAAAA;
            }      
        },
    }
}
</script>

<style></style>