export default {
  namespaced: true,
  state: {
    items: [],
    tiposConcepto: [
      {
        value: false,
        nombre: "Específico de gasto",
      },
      {
        value: true,
        nombre: "Catálogo personalizado",
      },
    ],
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
  },
  actions: {},
};
