import { http_client } from "@/plugins/http_client";

const crearSolicitud = async (params = {}) =>
  await http_client("/api/destacamiento/crear", params, "post");

const actualizarSolicitud = async ($idSolicitud, params = {}) =>
  await http_client(`/api/destacamiento/editar/${$idSolicitud}`, params, "put");

const obtenerSolicitud = async (idSolicitud, idMovimiento, params ={}) =>
  await http_client(
    `/api/destacamiento/detalle-solicitud/${idSolicitud}/${idMovimiento}`,
    params,
    "get"
  );

const obtenerSolicitudes = async (page, params = {}) =>
  await http_client(`/api/destacamiento/listado?page=${page}`, params, "get");

const crearDocumentos = async (params = {}) =>
  await http_client("/api/destacamiento/crear-documento", params, "post");

const crearDocumentosTraslado = async (params = {}) =>
  await http_client("/api/traslado/crear-documento", params, "post");

const obtenerDocumentos = async (idSolicitud, page) =>
  await http_client(
    `api/destacamiento/listar-documentos/${idSolicitud}?page=${page}`,
    {},
    "get"
  );

  const obtenerDocumentosTraslado = async (idSolicitud, page) =>
    await http_client(
      `api/traslado/listar-documentos/${idSolicitud}?page=${page}`,
      {},
      "get"
    );

const eliminarDocumento = async (idDocumento, params = {}) =>
  await http_client(
    `api/destacamiento/eliminar-documento/${idDocumento}`,params,"delete"
  );

  const eliminarDocumentoTraslado = async (idDocumento) =>
    await http_client(
      `api/traslado/eliminar-documento/${idDocumento}`,
      {},
      "delete"
    );

const crearCargosDetallesSolicitud = async (params = {}) =>
  await http_client(
    `api/destacamiento/detalle-solicitud-cargo`,
    params,
    "post"
  );

  const crearCargosDetallesSolicitudTraslado = async (params = {}) =>
    await http_client(
      `api/traslado/detalle-solicitud-cargo`,
      params,
      "post"
    );

const otenerDetallesCargosSolicitud = async (idSolicitud, page) =>
  await http_client(
    `api/destacamiento/listar-cargos/${idSolicitud}?page=${page}`
  );

  const otenerDetallesCargosSolicitudTraslado = async (idSolicitud, page) =>
    await http_client(
      `api/traslado/listar-cargos/${idSolicitud}?page=${page}`
    );

const obtenerDetalleCargos = async (idDetalle, params = {}) =>
  await http_client(
    `api/destacamiento/show-detalle-cargo/${idDetalle}`,
    params,
    "get"
  );

  const obtenerDetalleCargosTraslado = async (idDetalle, params = {}) =>
    await http_client(
      `api/traslado/show-detalle-cargo/${idDetalle}`,
      params,
      "get"
    );

const deleteDetalleCargo = async (idDetalle, params = {}) =>
  await http_client(
    `api/destacamiento/detalle-solicitud-cargo/eliminar/${idDetalle}`,
    params,
    "delete"
  );

  const deleteDetalleCargoTraslado = async (idDetalle, params = {}) =>
    await http_client(
      `api/traslado/detalle-solicitud-cargo/eliminar/${idDetalle}`,
      params,
      "delete"
    );
const editarDetalleCargo = async (idDetalle, params = {}) =>
  await http_client(
    `api/destacamiento/detalle-solicitud-cargo/editar/${idDetalle}`,
    params,
    "put"
  );

  const editarDetalleCargoTraslado = async (idDetalle, params = {}) =>
    await http_client(
      `api/traslado/detalle-solicitud-cargo/editar/${idDetalle}`,
      params,
      "put"
    );

const verDocumento = async (idDocumento, params = {}) =>
  await http_client(
    `api/destacamiento/ver-documento/${idDocumento}`,
    params,
    "get"
  );

  const verDocumentoTraslado = async (idDocumento, params = {}) =>
    await http_client(
      `api/traslado/ver-documento/${idDocumento}`,
      params,
      "get"
    );

const aprobarSolicitud = async (params = {}) =>
  await http_client(`api/destacamiento/aprobar-solicitud`, params, "post");

const aprobarSolicitudTraslado = async (params = {}) =>
  await http_client(`api/traslado/aprobar-solicitud`, params, "post");

const eliminarPersona = async (idDetalleSolicitud, params = {}) =>
  await http_client(
    `api/destacamiento/detalle-solicitud-persona/delete/${idDetalleSolicitud}`,
    params,
    "delete"
  );

  const eliminarPersonaTraslado = async (idDetalleSolicitud, params = {}) =>
    await http_client(
      `api/traslado/detalle-solicitud-persona/delete/${idDetalleSolicitud}`,
      params,
      "delete"
    );

const aprobarTitular = async (params = {}) =>
  await http_client(`api/destacamiento/aprobacion-titular`, params, "post");

const aprobarTitularTraslado = async (params = {}) =>
  await http_client(`api/traslado/aprobacion-titular`, params, "post");

const observarSolicitud = async (params = {}) =>await http_client('api/destacamiento/observar-solicitud',params, "post");
const observarSolicitudTraslado = async (params = {}) =>await http_client('api/traslado/observar-solicitud',params, "post");
const confirmarHoras = async (idDetallepersona,params = {}) => await http_client(`api/destacamiento/confirmar-horas/${idDetallepersona}`,params, "post");

const confirmarHorasTraslado = async (idDetallepersona,params = {}) => await http_client(`api/traslado/confirmar-horas/${idDetallepersona}`,params, "post");
const cambiarEstadoDetallePersona = async (idDetallePersona, params = {}) => await http_client(`api/destacamiento/cambiar-estado/${idDetallePersona}`,params, "post");
const cambiarEstadoDetallePersonaTraslado = async (idDetallePersona, params = {}) => await http_client(`api/traslado/cambiar-estado/${idDetallePersona}`,params, "post");
const denegarSolicitud = async (params ={}) => await http_client(`api/destacamiento/denegar-solicitud`, params, "post");

const denegarSolicitudTraslado = async (params ={}) => await http_client(`api/traslado/denegar-solicitud`, params, "post");
const obtenerDetalleObservacion = async (idSolicitud, params ={}) => 
  await http_client(
    `api/destacamiento/notificacion-observacion/${idSolicitud}`,
    params,
    "get"
  )

  const obtenerDetalleModificacion = async (idSolicitud, params ={}) => 
    await http_client(
      `api/destacamiento/notificacion-modificacion/${idSolicitud}`,
      params,
      "get"
    )
const obtenerObservacion = async (idSolicitud, params = {}) =>
  await http_client(
    `api/destacamiento/observaciones/${idSolicitud}`,
    params,
    "get"
  )

const eliminarSolicitud = async (idSolicitud, params = {}) =>
    await http_client(`api/destacamiento/eliminar-registros-solicitud/${idSolicitud}`, params, 'delete');

const eliminarSolicitudTraslado = async (idSolicitud, params = {}) =>
  await http_client(`api/traslado/eliminar-registros-solicitud/${idSolicitud}`, params, 'delete');

export default {
  crearSolicitud,
  obtenerSolicitud,
  obtenerSolicitudes,
  crearDocumentos,
  obtenerDocumentos,
  eliminarDocumento,
  actualizarSolicitud,
  crearCargosDetallesSolicitud,
  otenerDetallesCargosSolicitud,
  obtenerDetalleCargos,
  deleteDetalleCargo,
  editarDetalleCargo,
  verDocumento,
  aprobarSolicitud,
  eliminarPersona,
  aprobarTitular,
  observarSolicitud,
  confirmarHoras,
  cambiarEstadoDetallePersona,
  denegarSolicitud,
  obtenerDetalleObservacion,
  obtenerObservacion,
  eliminarSolicitud,
  obtenerDetalleModificacion,
  otenerDetallesCargosSolicitudTraslado,
  editarDetalleCargoTraslado,
  crearCargosDetallesSolicitudTraslado,
  obtenerDetalleCargosTraslado,
  deleteDetalleCargoTraslado,
  obtenerDocumentosTraslado,
  verDocumentoTraslado,
  eliminarDocumentoTraslado,
  eliminarSolicitudTraslado,
  aprobarSolicitudTraslado,
  aprobarTitularTraslado,
  confirmarHorasTraslado,
  denegarSolicitudTraslado,
  observarSolicitudTraslado,
  eliminarPersonaTraslado,
  cambiarEstadoDetallePersonaTraslado,
  crearDocumentosTraslado
};