<template>
  <v-text-field
    style="border-radius: 12px"
    :color="color"
    :clearable="clearable"
    :dense="dense"
    :disabled="disabled"
    :error-messages="errorMessages"
    :label="label"
    :maxlength="length"
    :readonly="readonly"
    :value="isFocused ? real_value : format_value"
    @blur="ObserverBlurFtn"
    @focus="
      isFocused = true;
      $emit('focus');
    "
    @input="HandlerInputFtn"
    @keypress="HandlerKeypressFtn"
    outlined
    ref="textfield"
    v-paste-prevent
    @change="$emit('change', $event)"
  />
</template>

<script>
/*
 * directiva para codicionar y validar el pegado de texto adecuado del input
 */
const pastePreventDirective = {
  inserted: function (el) {
    el.addEventListener("paste", function (event) {
      const value = (event.clipboardData || window.clipboardData).getData(
        "text"
      );

      const pattern = "^(?:[0-9]{0,15}(?:\\.[0-9]{0,2})?|0?\\.[0-9]{1,2})$";
      const regex = new RegExp(pattern);
      if (!regex.test(value)) {
        event.preventDefault();
        return;
      }
    });
  },
};

export default {
  name: "AppMoneyComponent",

  directives: {
    "paste-prevent": pastePreventDirective,
  },

  props: {
    // textfield attributes
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "bluePrimary",
    },
    length: {
      type: Number,
      default: 14,
    },
    // v-model
    value: {
      type: String | Number,
      default: "",
    },
  },

  data: () => ({
    isFocused: false,

    format_value: "",
    real_value: null,
    old_value: null,
  }),

  methods: {
    // funciones para referencia externa
    input(value) {
      this.InitFtn(value);
    },
    clean() {
      this.ClearFtn();
    },

    /**
     * @description Validacion del valor inicial e inyeccion
     */
    InitFtn(value) {
      // verificar si es string
      if (typeof value === "string") {
        console.error("AppMoneyComponent: Value is string");
        return;
      }

      // verificar si es posee mas de 2 decimales o contiene exponente
      if (value != null) {
        const str = value.toString();
        const index = str.indexOf(".");
        if (index != -1) {
          const decimals = str.substring(index + 1);
          if (decimals.length > 2) {
            console.error("AppMoneyComponent: Decimals >= 2 or contains e+");
            return;
          }
        }
      }

      // verificar si es un numero valido
      if (isNaN(value)) {
        console.error("AppMoneyComponent: Invalid value");
        return;
      }

      // verificar si es negativo
      if (value < 0) {
        console.error("AppMoneyComponent: Cannot be negative");
        return;
      }

      // registro de valor inicial
      if (value != null) {
        this.real_value = Number(value);
        this.format_value = this.FormatCurrencyPrt(value, true);
        return;
      }
    },

    /**
     * @description Funcion q dispara la funcinalidad de formateo
     */
    ObserverBlurFtn() {
      if (this.old_value != this.real_value) {
        // verificar si es nulo, formato en base a eso
        if (this.real_value != null) {
          this.format_value = this.FormatCurrencyPrt(this.real_value, true);
        } else {
          this.format_value = "";
        }

        this.$emit("input", this.real_value);
        this.old_value = this.real_value;
      }

      this.$emit("blur");
      this.isFocused = false;
    },

    /**
     * @description Funcion q formatea el valor ingresado
     * @param {Number} value
     */
    HandlerInputFtn(value) {
      if (value != null && value != "") {
        this.real_value = Number(value);
        this.format_value = this.FormatCurrencyPrt(value, true);
      } else {
        this.real_value = null;
        this.format_value = "";
      }
    },

    /**
     * @description Funcion q verifica los valores ingresados, condicionando valores adecuados
     *
     */
    HandlerKeypressFtn(event) {
      // solo un cero
      if (this.real_value != null) {
        if (this.real_value == 0 && event.key == "0") {
          event.preventDefault();
        }
      }

      // solo un punto, e inicio de decimales
      if (event.key == ".") {
        if (this.real_value == null) {
          this.real_value = 0.0;
        } else {
          const lazy_value = this.$refs.textfield.lazyValue;
          if (lazy_value) {
            const str = lazy_value.toString();
            const index = str.indexOf(".");
            if (index != -1) {
              event.preventDefault();
            }
          }
        }
      } else {
        // solo numeros
        if (isNaN(event.key)) {
          event.preventDefault();
        }
      }

      // solo dos decimales
      if (this.real_value != null) {
        const str = this.real_value.toString();
        const index = str.indexOf(".");
        if (index != -1) {
          const decimals = str.substring(index + 1);
          if (decimals.length >= 2) {
            event.preventDefault();
          }
        }
      }

      // maximo 15 caracteres
      if (this.real_value != null) {
        if (this.real_value.toString().indexOf(".") == -1) {
          const str = this.real_value.toString();
          if (str.length >= 15) {
            event.preventDefault();
            return;
          }
        }
      }
    },

    ClearFtn() {
      this.old_value = null;
      this.real_value = null;
      this.format_value = "";
    },
  },

  watch: {
    value(val) {
      if (val == null) {
        this.ClearFtn();
      }
    },
  },

  mounted() {
    this.isFocused = this.$refs.textfield.isFocused;
    this.InitFtn(this.value);
  },
};
</script>
