import { http_client } from "@/plugins/http_client";

const listarUnidadesOrganizativas = async () =>
    await http_client(`/api/listar-unidades-organizativas`, {}, "get");

const getEmpleadosEstablecimiento = async (params = {}) =>
    await http_client("api/v1/gestion-roles/rol/empleados", params);

const getEmpleadoConRoles = async ( idEmpleadoPuesto, params = {}) =>
    await http_client(`api/v1/gestion-roles/rol/empleados/${idEmpleadoPuesto}`, params);

const buscarRolPorNombre = async (params = {}) =>
    await http_client(`api/v1/gestion-roles/rol/permisos/buscar-nombre`, params);

const asignarRolEmpleado = async (idRol, idEmpleadoPuesto, params = {}) =>
    await http_client(`api/v1/gestion-roles/rol/${idRol}/empleados/${idEmpleadoPuesto}`, params, "post");

const eliminarRolEmpleado = async (idRol, idEmpleadoPuesto, params = {}) =>
    await http_client(`api/v1/gestion-roles/rol/${idRol}/empleados/${idEmpleadoPuesto}`, params, "delete");

export default {
    listarUnidadesOrganizativas,
    getEmpleadosEstablecimiento,
    getEmpleadoConRoles,
    buscarRolPorNombre,
    asignarRolEmpleado,
    eliminarRolEmpleado
}