<template>
  <v-row class="flex-row text-center ml-n8">
    <v-col cols="12" sm="12" md="12" class="mx-auto">
      <h1 class="btnAzul--text text-h4 font-weight-bold">
        {{ title }}
      </h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AppTitleComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    breakpoint() {
      if (this.$vuetify.breakpoint.sm) {
        return "text-h6";
      } else if (this.$vuetify.breakpoint.xs) {
        return "text-h5";
      } else if (this.$vuetify.breakpoint.md) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.lg) {
        return "text-h4";
      } else if (this.$vuetify.breakpoint.xl) {
        return "text-h4";
      } else {
        return "text-h4";
      }
    },
  },
};
</script>

<style></style>
