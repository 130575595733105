import { http_client } from "@/plugins/http_client";
import solicitud from "@/views/acciones-movimientos/services/solicitud";

const obtenerSolicitudesTraslados= async(page,params={})=> await http_client(`/api/traslado/traslado-personal?page=${page}`, params, "get")
const crearSolicitudesTraslados = async(params={})=> await http_client(`/api/traslado/crear`, params, "post")
const editarSolicitudTraslados = async (idSolicitud, params ={}) => 
    await http_client(
        `api/traslado/editar/${idSolicitud}`,
        params,
        "put"
    )
const getInfoObservacionTraslado = async (idSolicitud, params ={}) => 
        await http_client(
            `api/traslado/notificacion-observacion/${idSolicitud}`,
            params,
            "get"
        )
const getObservacionTraslado = async (idSolicitud, params ={}) => 
        await http_client(
            `api/traslado/observaciones/${idSolicitud}`,
             params,
            "get"
        )

const obtenerDetalleModificacionTraslado = async (idSolicitud, params ={}) => 
        await http_client(
            `api/traslado/notificacion-modificacion/${idSolicitud}`,
            params,
            "get"
        )
const getDetalleSolicitudTraslado = async (idSolicitud, idMovimiento, params ={}) =>
    await http_client(
        `/api/traslado/detalle-solicitud/${idSolicitud}/${idMovimiento}`,
         params,
        "get"
    )


const obtenerDetallePlaza = async (params ={}) => await http_client(`/api/traslado/show-plaza`, params, "get")
const obtenerUnidadesPresupuestarias = async (params ={}) => await http_client(`/api/traslado/unidad-presupuestaria`, params, "get")
const obtenerLineaTrabajo = async (params ={}) => await http_client(`/api/traslado/linea-trabajo`, params, "get")
const obtenerPlazas = async (params ={}) => await http_client(`/api/traslado/plaza`, params, "get")
const guardarDetallePlaza = async (params ={}) => await http_client(`/api/traslado/detalle-solicitud-plaza`, params, "post")
const obtenerDetallePlazas = async (params ={}) => await http_client(`/api/traslado/listar-solicitud-plazas`, params, "get")

const obtenerDetallePlazasAutorizacion = async (params ={}) => await http_client(`/api/traslado/solicitud-final`, params, "get")
const obtenerDetallePlazaEspecifica = async (solicitud_id, params ={}) => await http_client(`/api/traslado/detalle-plaza/${solicitud_id}`, params, "get")
const eliminarDetallePlaza = async (detalle_plaza_id, params = {}) => await http_client(`/api/traslado/detalle-plaza-permanente/${detalle_plaza_id}`, params, "delete")
const eliminarDetalle = async (detalle_plaza_id, params = {}) => await http_client(`/api/traslado/detalle-plaza/${detalle_plaza_id}`, params, "delete")
const buscarVacantesPlaza = async (params ={}) => await http_client(`/api/traslado/plazas-vacantes`, params, "get")

const obtenerPlazasAsignadas = async (params ={}) => await http_client(`/api/traslado/asignar-plaza-vacante`, params, "post")

const obtenerPlazasAsignadasUnidad = async (params ={}) => await http_client(`/api/traslado/asignar-plaza-vacante-unidad`, params, "post")

const eliminarPlazaAsignadaRRHHB = async (subpartida_id, params ={}) => await http_client(`/api/traslado/plaza-vacante/${subpartida_id}`, params, "delete")

const eliminarPlazaAsignadaUnidadB = async (subpartida_id, params ={}) => await http_client(`/api/traslado/plaza-vacante-unidad/${subpartida_id}`, params, "delete")


const eliminarPlazaAsignada = async (params ={}) => await http_client(`/api/traslado/plazas-vacantes-unidad-eliminada`, params, "delete")


const obtenerPlazasSeleccionadas = async ( params ={}) => await http_client(`/api/traslado/plazas-vacantes-selectas`, params, "get")

const obtenerPlazasSeleccionadasUnidad = async ( params ={}) => await http_client(`/api/traslado/plazas-vacantes-selectas-unidad`, params, "get")

const eliminarPlazaSeleccionada = async (solicitud_id, params ={}) => await http_client(`/api/traslado/cancelar-plazas-vacantes/${solicitud_id}`, params, "delete")

const listarPlazasPorUnidad = async (params ={}) => await http_client(`/api/traslado/listar-plazas-peticiones`, params, "get")

const eliminarDetallePlazaSolicitud = async (idDetalle, params ={}) => await http_client(`/api/traslado/detalle-plaza-solicitud/${idDetalle}`, params, "delete")

export default{
    obtenerSolicitudesTraslados,
    crearSolicitudesTraslados,
    editarSolicitudTraslados,
    getInfoObservacionTraslado,
    getObservacionTraslado,
    getDetalleSolicitudTraslado,
    obtenerDetallePlaza,
    obtenerUnidadesPresupuestarias,
    obtenerLineaTrabajo,
    obtenerPlazas,
    guardarDetallePlaza,
    obtenerDetallePlazas,
    obtenerDetallePlazaEspecifica,
    eliminarDetallePlaza,
    buscarVacantesPlaza,
    obtenerPlazasAsignadas,
    obtenerPlazasSeleccionadas,
    eliminarPlazaSeleccionada,
    eliminarDetalle,
    eliminarPlazaAsignadaRRHHB,
    obtenerDetalleModificacionTraslado,
    listarPlazasPorUnidad,
    eliminarPlazaAsignada,
    eliminarDetallePlazaSolicitud,
    eliminarPlazaAsignadaUnidadB,
    obtenerPlazasSeleccionadasUnidad,
    obtenerDetallePlazasAutorizacion,
    obtenerPlazasAsignadasUnidad
}