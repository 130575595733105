<template>
  <div>
    <v-row justify="center">
      <v-expansion-panels accordion class="rounded-xl" >
        <v-expansion-panel v-for="comentario in comentarios" :key="comentario.id">
          <v-expansion-panel-header class="justify-start"><span class="font-weight-black text-h5"><v-icon
                :color="comentario.solventada ? '#69bcc5':'#F3A723'">{{comentario.solventada ? 'mdi-check-circle-outline':'mdi-alert-outline'}}</v-icon>
              {{ comentario.nombre_unidad_organizativa }}</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <p class="text-subtitle-1">{{ comentario.usuario }} {{ comentario.fecha }}</p>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <p class="text-h6">
                  {{ comentario?.observacion }}
                </p>
              </v-col>
            </v-row>
            <button-components v-if="!comentario.solventada && $route.meta?.solventar" :disabled="disabled" :colors="'#E4A90B'" :title="'Solventar'" class="mt-5"
              @btnAccion="showDialogSolventar = true" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <app-dialog-alert :dialogAlert="showDialogSolventar" :titulo="titulo" :mensaje="mensaje" btnAccionesTxt="Aceptar"
      btnAccionesTxtCancelar="Cancelar" :clasesTitulo="clasesTitulo" @dialogCerrarAlert="showDialogSolventar = false"
      @dialogAceptaralert="solventar" :disabled="disabled"/>
  </div>

</template>

<script>
import ButtonComponents from '../views/acciones-movimientos/components/ButtonComponents.vue';
import AppDialogAlert from "../views/acciones-movimientos/components/AppDialogAlert.vue";
export default {
  components: {
    ButtonComponents,
    AppDialogAlert
  },
  props: {
    unidad: {
      type: String,
      default: 'Unidad'
    },
    nombre: {
      type: String,
      default: 'Juan Perez'
    },
    fecha: {
      type: String,
      default: '01/02/2024 14:20'
    },
    observacion: {
      type: String,
      default: 'Observacion'
    }
  },
  data() {
    return {
      disabled: false,
      titulo: '¿Deseas solventar la solicitud?',
      mensaje: 'Al confirmar la acción no se podrá revertir los cambios',
      showDialogSolventar: false,
      clasesTitulo: {
        'font-weight': 'bold',
        'font-size': '20px',
        'text-align': 'center',
        color: '#1C1E4D',
        with: '100%'
      },
      comentarios:[]
    }
  },
  methods: {
    solventar() {
      this.$emit('solventar')
      this.showDialogSolventar = false
      this.disabled = true
    },
    async detalleObservacion() {
      try {
        this.$store.commit("utils/setLoader", true);
        const {data: { data }, status} =
          await this.services.movimientos.ascenso.obtenerDetalleObservacion(
            this.$route.params.id
          );
        if (status === 200) {
          this.comentarios = data;
          if(this.comentarios.length) this.eventBus.$emit('showButtonSend', false)
        }
        this.$store.commit("utils/setLoader", false);
      } catch (error) {
        console.log('observacion error', error);
        this.$store.commit("utils/setLoader", false);
      }
    },
  },
  created() {
    if(this.$route.params.id) this.detalleObservacion();
  }
}
</script>