export default [
	{
		path: 'acciones-movimientos/listado_plazas/:id_tipo_contratacion',
		name: 'acciones-movimientos-listado-plazas',
		component: () => import('@/views/acciones-movimientos/listado_plazas/views/DetallePlazas.vue'),
	},
	{
		path: 'acciones-movimientos/listado_plazas',
		name: 'acciones-movimientos-listado-estructura-plazas',
		component: () => import('@/views/acciones-movimientos/listado_plazas/views/EstructuraPlazas.vue'),
	},
];
