import { http_client } from "@/plugins/http_client";


function getParams(params) {
    return params ? new URLSearchParams(Object.entries(params)).toString() : "";
}

const cifradoPresupuestario = {
    listar: ({params, data}) => http_client('/api/mnt_cifrado/search?' + getParams(params), data, 'post'),
    crear: (body) => http_client('/api/mnt_cifrado', body, 'post'),
    desactivar: (body) => http_client("/api/mnt_cifrado/" + body.id, {}, 'delete'),
};



export default {
    cifradoPresupuestario
};

